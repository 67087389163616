import { Component } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-seocontent',
  templateUrl: './seocontent.component.html',
  styleUrls: ['./seocontent.component.scss']
})

export class SeocontentComponent {
    ProductId: any;
    SeoLinkContent: any = [];
    SeoLinkContent123: any = [];


    constructor(public generalservice: GeneralService, private activateroute: ActivatedRoute) {
        this.ProductId = this.activateroute.snapshot.paramMap.get("Seolinkname");
    }

    ngOnInit() {
        this.seocontent();
    }

    seocontent() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append("Param", this.ProductId);
        var url = "api/Azole/Get_SeoLinkData_BasedOnID";
        this.generalservice.PostData(url, uploadfile).then((data: any) => {
            debugger
            this.SeoLinkContent123 = data;
            this.SeoLinkContent = data.map(item => {
                return {
                    TitleContent: this.highlightDynamicContent(item.TitleContent)
                };
            })
        })
    }

    highlightDynamicContent(content: string): string {
        var tempElement = document.createElement("div");
        tempElement.innerHTML = content;

        // Example: Highlight <h1>, <h2>, <strong> tags, or other specific content
        const tagsToHighlight = ['h1', 'h2', 'strong', 'b','br']; // Tags you want to highlight
        tagsToHighlight.forEach(tag => {
            // Find elements with the given tag name and wrap them with a <mark> tag
            let elements = tempElement.getElementsByTagName(tag);
            Array.from(elements).forEach((el) => {
                el.innerHTML = `<mark>${el.innerHTML}</mark>`;
            });
        });

        // Convert back to HTML string
        return tempElement.innerHTML;
    }


}
