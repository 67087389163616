<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Addresses</div>

                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">  <i class="fas fa-home"></i>  Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Addresses</b></li>
            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 ">
                <h3 class="address">Your Addresses  </h3>
            </div>

            <!-- Always display the plus icon -->
            <div class="col-lg-4">
                <div class="borders">
                    <div class="row">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-8">
                            <i class="fa fa-plus addIcon" (click)="plus()" pTooltip="ADD" tooltipPosition="top"></i>
                            <h4 class="text-muted">Add address</h4>
                        </div>
                        <div class="col-lg-2"></div>
                    </div>
                </div>
            </div>

            <ng-container *ngFor="let record of category; let i = index;">
                <!-- Create a new row after every third record or for the first record -->
                <div *ngIf="i % 3 === 0" class="row">
                    <!-- Display three records in each row -->
                    <div class="col-lg-4 mb-3" *ngFor="let rec of category.slice(i, i + 3)">
                        <div class="card card_bgg">
                            <div class="card-header">
                                <p>Address Type : <span class="text-black">{{rec.Categoryname}}</span></p>
                            </div>
                            <div class="card-body  p-3">
                                <ul class="list-unstyled">
                                    <li class="text-black font-weight-bold">{{rec.Customername}}</li>
                                    <li>{{rec.Mobileno}}</li>
                                    <li>{{rec.HNo}}</li>
                                    <li>{{rec.Address}}</li>
                                    <li>{{rec.Landmark}}</li>
                                    <li>{{rec.City}}</li>
                                    <li>{{rec.State}}</li>
                                    <li>{{rec.Pincode}}</li>
                                </ul>
                                <br />
                                <div class="action-buttons">
                                    <span (click)="edit(rec.AID,rec.State)" class="edit-link" style="cursor:pointer;">Edit</span>
                                    <span class="separator"> | </span>
                                    <span (click)="deleteRecord(rec.AID)" class="delete-link" style="cursor:pointer;">Delete</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</div>
