<div id="about" class="about-area ptb-100">
    <br />

 




    <div class="container mobilescrn">
        <div class="row ">
            <h3 class="a_heading headingg">Add Address</h3>
            <br />
            <div class="col-lg-3"></div>
            <div class="col-lg-6 col-md-6 vol-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12 mb-3">
                        <label for="category">Address Type<span style="color:red">*</span></label>
                        <div class="add_input">
                            <p-autoComplete [(ngModel)]="Categoryname" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                            field="Categoryname" [dropdown]="true" [size]="90"
                                            placeholder="Select Address type" [minLength]="1">
                            </p-autoComplete>
                        </div>
                        <small *ngIf="form.controls.Categoryname.touched && form.controls.Categoryname.errors?.required"
                               class="p-error" style="color:red">Address type is required.</small>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-6 col-sm-12 ol-xs-12">
                        <label>Full name (First and Last name)<span style="color:red">*</span></label>
                        <input type="text" placeholder="Enter Name" class="add_input" [(ngModel)]="Customername" />
                    </div>
                    <div class="col-lg-12 col-md-6 col-sm-12 col-xs-12">
                        <label>Mobile number<span style="color:red">*</span></label>
                        <input class="add_input" type="text" maxlength="10" [(ngModel)]="Mobileno"
                               placeholder="Please enter 10 digits Mobile No" (input)="validateMobileNumber()"  />
                     
                        </div>
                </div>







                <div class="row">
                   
                    <div class="col-lg-12 col-md-6 col-sm-12 ol-xs-12">
                        <label>Flat, House no <span style="color:red">*</span></label>
                        <input class="add_input" type="text" placeholder="Enter HNo/Flat No" [(ngModel)]="HNos" />
                    </div>

                </div>



                <div class="row">
                    <div class="col-lg-12 col-md-6 col-sm-12 ol-xs-12">
                        <label>Landmark<span style="color:red">*</span></label>
                        <input class="add_input" type="text" placeholder="Enter Landmark" [(ngModel)]="Landmarks" />
                    </div>
                    <div class="col-lg-12 col-md-6 col-sm-12 ol-xs-12">
                        <label>Town/City<span style="color:red">*</span></label>
                        <input class="add_input" type="text" placeholder="Enter City" [(ngModel)]="Citys" />
                    </div>
                </div>

                <div class="row">
                    <!--<div class="col-lg-12 col-md-6 col-sm-12 ol-xs-12">
                        <label>State<span style="color:red">*</span></label>
                        <input class="add_input" type="text" placeholder="Enter State" [(ngModel)]="States" />
                    </div>-->
                    <div class="col-lg-12 col-md-6 col-sm-12 ol-xs-12">
                        <label class="head_txt"> <b class="task">State Name<span style="color:red;">*</span></b></label>
                        <div class="form-group">
                            <p-autoComplete [suggestions]="statelist" (completeMethod)="filterGroupsSingle7($event)" dataKey='Id' field="StateName" [(ngModel)]="StateName" [dropdown]="true" [size]="50"
                                            placeholder="Search with State " [minLength]="1" [forceSelection]="true">
                                <ng-template let-autofillCustomers pTemplate="item">
                                    <div class="card pb-4 ps-5 drop"  style="height:15px; border:none;padding-left:10px;">
                                        {{autofillCustomers.StateName}}
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-12 ol-xs-12">
                        <label>Pincode<span style="color:red">*</span></label>
                        <input class="add_input" placeholder="Enter Pincode" type="tel" pattern="[0-9]*" maxlength="6" [(ngModel)]="Pincodes" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <label>Address</label>
                        <textarea class="add_input" type="text" placeholder="Enter Address" [(ngModel)]="Addresss"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-2"></div>
                    <div class="col-lg-3 col-4">
                        <button class="btn-danger btn" (click)="Back()">Cancel</button>
                    </div>
                    <div class="col-lg-3 col-4" *ngIf="addid == undefined">
                        <button class="btn-success btn" (click)="InsertAdderss(Customername, Mobileno, HNos, Addresss, Landmarks, Citys, StateName, Pincodes, Categoryname, ACID)" [disabled]="isButtonDisabled">Add</button>
                    </div>

                    <div class="col-lg-3 col-2"></div>
                </div>


            </div>


                <!--<div class="col-lg-6">
                    <img src="../../../assets/Images\location.png" />

                </div>-->
            </div>
    </div>
</div>

