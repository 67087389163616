import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service';
import { Router, ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
    public form: FormGroup;
    arr: any = [];
    passwordshow: boolean = false;
    Mobilenum: any; checkNo: any; checkemail:any
    mobiledata: any;
    HomeUrl: any;
    token: any;
    randomnumber: any;
    EMailID: any;
    Category: any;
    BranchdropList: any; GST_TreatmentName: any;
    enableGSTNoField: any; Email: any;
    showGSTNumberField: boolean = false;
    OTP: any; public ZOHO_Books_ID: any;
    public CRMID: any; LoginDetails: any;
    LoginDetails1: any; profiledata: any;
    isButtonDisabled: boolean = false;
    isOtpSent: boolean = false;
    isButtonDisabled1: boolean = false;
    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(public generalservice: GeneralService, public fb: FormBuilder, public http: HttpClient,
        private router: Router) {
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            //this.HomeUrl = 'http://localhost:57391/'
         

        });

        this.form = this.fb.group({
            CompanyName: ['', Validators.required],
            UserName: ['', Validators.required],
            EMailID: ['', [Validators.required, Validators.email]],
            Phone1: ['', Validators.required],
            City: ['', Validators.required],
            PWD: ['', Validators.required],
            GST_TreatmentName: ['', Validators.required],
            GSTNo: ['', [Validators.required, this.gstValidator]],
            OTP: ['', Validators.required],
        })

    }



    ngOnInit() {
        this.GetCategory()
        this.form.get('GST_TreatmentName').valueChanges.subscribe((value) => {
            if (value && (value.GSTID === 2 || value.GSTID === 3 || value.GSTID === 5)) {
                this.showGSTNumberField = true;
            } else {
                this.showGSTNumberField = false;
            }
        });
    }

   
    GetCategory() { 

        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
                CreditLimit: 0,


            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeUrl + "api/Azole/GST_Treatment_Crud";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => { 

                this.Category = data;



            },
                err => {
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

    filterGroupsSingle4(event: any) { 
        // Your existing autocomplete filter logic goes here
        let filtered: any = [];
        let query = event.query;
        {
            this.BranchdropList = [];
            for (let i = 0; i < this.Category.length; i++) {
                let type = this.Category[i];
                if (type.GST_TreatmentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                }
            } 
            this.BranchdropList = filtered;
        }

        // Update GSTID when a value is selected from the autocomplete dropdown

    }
    onGSTTreatmentNameChange(selectedValue: any) {
        // Check if the selected GSTID is 2 or 5 to enable/disable the GST number field
        this.enableGSTNoField = selectedValue === '2' || selectedValue === '5';
    }


  

    onSubmit() {
        this.isButtonDisabled = true;
        const url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }

            // Validate required fields
            if (
                !this.form.value.CompanyName ||
                !this.form.value.UserName ||
                !this.form.value.EMailID ||
                !this.form.value.Phone1 ||
                !this.form.value.City ||
                !this.form.value.PWD ||
                !this.form.value.GST_TreatmentName ||
                !this.form.value.GST_TreatmentName.GSTID
            ) {
                this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Please fill in all fields', '');
                return;
            }

            if (!this.form.value.OTP) {
                this.generalservice.ShowAlert('error', 'Please provide the OTP.', 'error');
                return;
            }

            if (this.form.value.OTP !== this.randomnumber.toString()) {
                this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Invalid OTP. Please enter the correct OTP.', '');
                return;
            }

            // Check for existing mobile number and email
            if (this.checkNo === 10) {
                this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Mobile number already exists.', '');
                return;
            }

            if (this.checkemail === 12) {
                this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Email already exists.', '');
                return;
            }

            if (this.form.get('EMailID').hasError('email')) {
                this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Invalid email format.', '');
                return;
            }

            // Get GST Treatment ID
            const gstTreatmentId = this.form.value.GST_TreatmentName.GSTID;

            // Check if GST number is required and validate it
            if (gstTreatmentId === 2 || gstTreatmentId === 3 || gstTreatmentId === 5) {
                if (!this.form.value.GSTNo) {
                    this.generalservice.ShowAlert(
                        '<img src="../../../assets/icons/icons8-warning.gif" />',
                        'GST Number is required for the selected GST Treatment.',
                        ''
                    );
                    return;
                }

                if (this.form.get('GSTNo').errors?.invalidGST) {
                    this.generalservice.ShowAlert(
                        '<img src="../../../assets/icons/icons8-warning.gif" />',
                        'Invalid GST Number. A valid GST number should follow the pattern: 15 characters long, starting with 2 digits (01-37), followed by 5 alphabets, 4 digits, 1 alphabet, 1 alphanumeric, "Z", and 1 alphanumeric. For example: 22AAAAA0000A1Z5',
                        ''
                    );
                    return; // Prevent form submission
                }
            }

            // Prepare data for submission
            this.arr = [{
                CompanyName: this.form.value.CompanyName,
                UserName: this.form.value.UserName,
                EMailID: this.form.value.EMailID,
                Phone1: this.form.value.Phone1,
                City: this.form.value.City,
                PWD: this.form.value.PWD,
                GSTID: gstTreatmentId,
                GSTNo: gstTreatmentId === 2 || gstTreatmentId === 3 || gstTreatmentId === 5 ? this.form.value.GSTNo : null,
                UserTypeId: 2,
                CreditLimit: 0.00
              
            }];

            const uploadfile = new FormData();
            uploadfile.append("Param", JSON.stringify(this.arr));
            uploadfile.append("Flag", "1");

            const registerUrl = this.HomeUrl + "api/Azole/Azl_Registation_GAUD";
            const accessToken = this.token;

            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`
            });

            this.http.post(registerUrl, uploadfile, { headers }).subscribe(
                (data: any) => {
                    console.log('Response from registration API:', data);

                    if (data === "Mobile Exists") {
                        this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Mobile Number Already Exists', '');
                    } else if (data === "Email Exist") {
                        this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Email Already Exists', '');
                    } else {
                        const response = JSON.parse(data);
                        if (response.Status === "SUCCESS") {
                            // Update the arr object to include regid
                            this.arr[0].RegId = response.RegId;
                            this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Your registration completed successfully', 'Success');
                            this.isButtonDisabled = false;
                            // Store login details and send registration email
                            localStorage.setItem("LoginDetails", JSON.stringify(this.arr));
                            const emailUploadFile = new FormData();
                            emailUploadFile.append("Email", JSON.stringify(this.arr));
                            const emailUrl = "api/Azole/RegistrationMailTo_Admin";
                            this.generalservice.PostData(emailUrl, emailUploadFile).then(emailData => {
                                console.log('Email API response:', emailData);
                                this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Registration completed successfully and mail sent to admin for approval', 'Success');
                                this.isButtonDisabled = false;
                                localStorage.setItem("LoginDetails", JSON.stringify(this.arr));
                                this.router.navigate(['/navbar']).then(() => {
                                    window.location.reload();
                                });
                            }).catch(emailErr => {
                                console.error('Email API error:', emailErr);
                                this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-cancel.gif" />', 'Something went wrong while sending email, please try again later', '<img src="../../../assets/icons/icons8-cancel.gif" />');
                                this.isButtonDisabled = false;
                            });
                        } else {
                            this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-cancel.gif" />', 'Something went wrong, please try again later', '<img src="../../../assets/icons/icons8-cancel.gif" />');
                        }
                    }
                },
                (err) => {
                    console.error('Registration API error:', err);
                    this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-cancel.gif" />', 'Something went wrong, please try again later', '<img src="../../../assets/icons/icons8-cancel.gif" />');
                }
            );
        });
    }


    gstValidator(control: AbstractControl): { [key: string]: any } | null {
        const gstPattern = /^([0-2][0-9]|3[0-7])[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        return gstPattern.test(control.value) ? null : { 'invalidGST': true };
    }


    SendOtpForMobile() {
        debugger
        this.isButtonDisabled1 = true;
        if (this.checkemail === 12) {
            this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Email already exists.', '');
            return;
        }
       
        if (!this.form.value.EMailID) {
            this.isOtpSent = false;
        }
        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var url = 'api/Azole/SendOTPtoMail?EmailId=' + this.form.value.EMailID + '&OTP=' + this.randomnumber + '&Subject=OTP to register';
        this.generalservice.GetData(url).then(data => {
            if (data == "SUCCESS") {
                
            }
            else {
                //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            }
        });
    }

    /*check mobile exist or not*/
    CheckMobileNo() { 
        this.Mobilenum = this.form.get('Phone1').value;
        var UploadFile = new FormData();
        var UploadFile = new FormData();
        UploadFile.append("Param2", this.Mobilenum);
        UploadFile.append("Param1", "1");
        var url = "api/Azole/Get_Check_Mobile_Email" 
        this.generalservice.PostData(url, UploadFile).then(data => {
            this.mobiledata = data; 
            if (data == 'Mobile Exists') {
                this.checkNo = 10;
            }
            else {
                this.checkNo = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }

    CheckEmail() {
        this.Email = this.form.get('EMailID').value;
        var UploadFile = new FormData();
        var UploadFile = new FormData();
        UploadFile.append("Param2", this.Email);
        UploadFile.append("Param1", "2");
        var url = "api/Azole/Get_Check_Mobile_Email"
        this.generalservice.PostData(url, UploadFile).then(data => {
            this.mobiledata = data;
            if (data == 'Email Exist') {
                this.checkemail = 12;
            }
            else {
                this.checkemail = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
    /*endregion*/


}

