<div id="about" class="about-area ptb-100" style="padding-bottom:0">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Leadership</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:white;">Leadership</li>
            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <p>
                    At Azole Chem, our leadership team is comprised of dynamic and experienced professionals who drive
                    our company's vision, values, and growth strategies. Led by Dr. xxxxx, our CEO, the leadership
                    team embodies a commitment to innovation, excellence, and customer satisfaction.
                </p>
            </div>
        </div>

    </div>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <div class="profile-card">
                        <div class="img">
                            <img src="https://1.bp.blogspot.com/-8c7QTLoyajs/YLjr2V6KYRI/AAAAAAAACO8/ViVPQpLWVM0jGh3RZhh-Ha1-1r3Oj62wQCNcBGAsYHQ/s16000/team-1-3.jpg">
                        </div>
                        <div class="caption">
                            <h3>Dr Vin Diesel</h3>
                            <p>Senior</p>
                            <div class="social-links">
                                <a href="#"><i class="fab fa-facebook"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="profile-card">
                        <div class="img">
                            <img src="https://1.bp.blogspot.com/-vhmWFWO2r8U/YLjr2A57toI/AAAAAAAACO4/0GBonlEZPmAiQW4uvkCTm5LvlJVd_-l_wCNcBGAsYHQ/s16000/team-1-2.jpg">
                        </div>
                        <div class="caption">
                            <h3>Dr David Corner</h3>
                            <p>Junior</p>
                            <div class="social-links">
                                <a href="#"><i class="fab fa-facebook"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="profile-card">
                        <div class="img">
                            <img src="https://1.bp.blogspot.com/-AO5j2Y9lzME/YLjr3mxiqAI/AAAAAAAACPE/KAaYYTtQTrgBE3diTbxGoc4U4fCGx-C2gCNcBGAsYHQ/s16000/team-1-4.jpg">
                        </div>
                        <div class="caption">
                            <h3>Dr Tom Cruise</h3>
                            <p>Junior</p>
                            <div class="social-links">
                                <a href="#"><i class="fab fa-facebook"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


       
        </div>
    </div>
</div>
