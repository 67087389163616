import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from './generalservice.service';

@Injectable({
    providedIn: 'root'
})
export class SessiontimeoutService {
    private sessionTimeoutMinutes = 30; // Adjust this as per your requirement
    private sessionTimeoutTimer: any;
    private loginDetails: any;

    constructor(private router: Router, private generalService: GeneralService) {
        this.initLoginDetails();
        this.resetSessionTimeout();
        this.initSessionTimeoutListener();
    }

    private initLoginDetails() {
        const loginDet = localStorage.getItem("LoginDetails");
        if (loginDet) {
            try {
                this.loginDetails = JSON.parse(loginDet);
            } catch (error) {
                console.error("Error parsing LoginDetails from localStorage:", error);
                this.loginDetails = null;
            }
        }
    }

    private resetSessionTimeout() {
        clearTimeout(this.sessionTimeoutTimer);
        this.sessionTimeoutTimer = setTimeout(() => {
            this.handleSessionTimeout();
        }, this.sessionTimeoutMinutes * 60 * 1000);
    }

    private initSessionTimeoutListener() {
        ['mousemove', 'scroll', 'keydown'].forEach(eventName => {
            window.addEventListener(eventName, () => this.resetSessionTimeout());
        });
    }

    private handleSessionTimeout() {
        // Show alert before navigating to login page
        this.generalService.ShowAlert('INFO', 'Your session has expired. Please log in again.', 'info');
        // Clear login details and navigate to login page
        this.logout();
    }

    logout() {
        // Clear login details from localStorage
        localStorage.removeItem("LoginDetails");
        // Reset loginDetails in memory
        this.loginDetails = null;
        // Navigate to login page
        this.router.navigate(['/Login']).then(() => {
            // Reload the page to reflect logout
            window.location.reload();
        });
    }

    // Add a method to check if user is logged in
    isLoggedIn(): boolean {
        return !!this.loginDetails; // Return true if loginDetails is truthy
    }
}
