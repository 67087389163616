
        <div id="about" class="about-area ptb-100">
            <nav aria-label="breadcrumb" class="breadcrumb">
                <div class="container">
                    <ul>
                        <div>About Us</div>
                        <li style="cursor:pointer;">
                            <a href="/" style="color:white;">
                                <i class="fas fa-home"></i> Home /
                            </a>
                        </li>
                        <li class="breadcrumb-item" style="color:white;">Vision & Mission</li>
                    </ul>
                </div>
            </nav>
            <section class="section_all bg-light" id="about">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section_title_all text-center">
                                <h3 class="font-weight-bold">Welcome To <span class="text-custom">Azolechem</span></h3>
                                <p class="section_subtitle mx-auto text-muted">Empowering Industries with Innovative Chemical Solutions</p>
                                <div class="">
                                    <i class=""></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row vertical_content_manage mt-5">
                        <div class="col-lg-6">
                            <div class="about_header_main mt-3">
                                <div class="about_icon_box">
                                    <p class="text_custom ">Driving Excellence, Enabling Success in Every Molecule</p>
                                </div>
                                <!--<h4 class="about_heading text-capitalize font-weight-bold mt-4">Lorem Ipsum is simply dummy text of the printing industry.</h4>-->
                                <p class="text-muted mt-3">
                                  
                                        Azolechem is a globally recognized leader in providing fine and specialty chemicals across diverse
                                        industries such as pharmaceuticals, crop science, biotechnology, healthcare, and chemical companies.
                                </p>

                                <p class="text-muted mt-3">
                                    With a steadfast commitment to innovation, quality, and customer satisfaction, we have positioned ourselves as a
                                    trusted partner for businesses seeking integrated solutions and cutting-edge products.
                                </p>
                                <p class="text-muted mt-3">Our journey began with a vision to emerge as an innovative, technology-driven, and cost-effective global player in the chemical industry.</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="img_about mt-3">
                                <img src="../../../assets/Images/about.webp" alt="" class="img-fluid mx-auto d-block">
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-lg-4">
                            <div class="about_content_box_all mt-3">
                                <div class="about_detail text-center">
                                    <div class="about_icon">
                                        <i class="fas fa-eye"></i>
                                    </div>
                                    <h5 class="text-dark text-capitalize mt-3 font-weight-bold">Our Vision</h5>
                                    <p class="edu_desc mt-3 mb-0 text-muted">Our goal is to play a pivotal role in shaping the industry's future by providing integrated, cutting-edge solutions that meet evolving customer needs worldwide. </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="about_content_box_all mt-3">
                                <div class="about_detail text-center">
                                    <div class="about_icon">
                                        <i class="fas fa-flag"></i>
                                    </div>
                                    <h5 class="text-dark text-capitalize mt-3 font-weight-bold">Our Mission</h5>
                                    <p class="edu_desc mb-0 mt-3 text-muted">our customers by providing high-quality chemicals and services that empower their research and development efforts, drive success, and create positive impacts in their respective fields.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="about_content_box_all mt-3">
                                <div class="about_detail text-center">
                                    <div class="about_icon">
                                        <i class="fas fa-quote-left"></i>
                                    </div>
                                    <h5 class=" text-capitalize mt-3 font-weight-bold">Our Motto</h5>
                                    <p class="edu_desc mb-0 mt-3 text-muted">Our motto, "Delivering Excellence, Enabling Success," reflects our dedication to delivering excellence in every aspect of our operations and empowering our customers' success through innovative solutions and superior service.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

  
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
