import { Component } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { SeoService } from '../../seo.service'
declare var $

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent {
    loginDet: any;
    OrderedProductList: any;
    HomeUrl: any;
    arr: any = [];
    schoolpro: any;
    schooldata1: any;
    loginDetails: any;
    ww: any; orderDetails: any;
    EnquriesProductList: any;
    productDetails: any;
    selectedTab: 'orders' | 'enquiries' = 'orders';
    token: any;
    id: any;
    constructor(private seoService: SeoService,public generalService: GeneralService, public fb: FormBuilder, public http: HttpClient, private route: ActivatedRoute,
        public router: Router) {
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        
    }
    ngOnInit(): void {
        this.seoService.updateMetaTags('My Orders', '');
        this.getOrders();
        this.GetEnquires();
        this.route.queryParams.subscribe(params => {
            // Set selectedTab based on the query parameter
            this.selectedTab = params['selectedTab'] || 'orders';
        });
}
    
    getOrders() {
       
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            var UploadFile = new FormData()
            UploadFile.append("Param", this.loginDetails[0].RegId);

            var url = this.HomeUrl + "api/Azole/Get_Orders_basedon_UserID";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {
                

                    this.OrderedProductList = data;
                },
                (err) => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }
    GetEnquires() {
      

        var UploadFile = new FormData()
        UploadFile.append("Param1", this.loginDetails[0].RegId);

        var url = "api/Azole/Get_MyEnquriesbasedonUID";
        this.generalService.PostData(url, UploadFile).then(data => {
          
            this.EnquriesProductList = data;

            this
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait a minute', 'error')

        })
    }
   

    navigateToInvoice(Invoice_Path) {
        debugger
        if (Invoice_Path != '') {
        const pdfUrl = this.HomeUrl + '/' + Invoice_Path;
        const anchor = document.createElement('a');      
        anchor.href = pdfUrl;      
        anchor.target = '_blank';     
            anchor.click();
        }
    }

    

   
}
