<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Products</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>

                </li>
                <li class="breadcrumb-item" style="color:white;">Products/</li>
                <li class="breadcrumb-item" style="color:white;">{{this.SeoLinkContent123[0].Title}}</li>
            </ul>
        </div>
    </nav><br />
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <img src="../../../assets/Images/Azole logo Logo_TM.svg" alt="logo" style="padding-top: 7rem;"/>
            </div>
            <div class="col-lg-9">
                <h1>{{this.SeoLinkContent123[0].Title}}</h1>
                <br />
                <p style="text-align:left;" [innerHTML]="SeoLinkContent[0]?.TitleContent"></p>
                <br />
                <p><b>Related Link</b></p>
                <p> {{this.SeoLinkContent123[0].RelatedLink1}}</p>
                <p>{{this.SeoLinkContent123[0].RelatedLink2}}</p>
            </div>
        </div>
    </div>


</div>
