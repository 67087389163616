<footer class="footer-area">

        <div class="container">

            <div class="row">


                <div class="col-lg-4">
                    <h3 class="Azole">Azolechem</h3>
                    <!--<small>PHARMACEUTICALS PVT. LTD.</small>-->
                    <!--<img src="assets/Azole/logo.webp" />-->
                    <div class="mt-3" style="color:white;">

                        Azolechem provides comprehensive catalog services and goods for the pharmaceutical, agrochemical, and other associated sectors. Azolechem offers premium partnership for goods and services that boost efficiency and productivity.
                    </div>

                    <br /> <br />

                </div>

                <div class="col-lg-5">
                    <div class="row">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-6"><h3>Our Links</h3></div>
                        <div class="col-lg-3"></div>

                    </div>

                    <div class="row" style="margin-top:-5%">
                        <div class="col-lg-6">
                            <p class="pb"><a href="/" style="  color:white;">Home</a></p>
                            <p class="pb"><a routerLink="/Companyprofle">About Us</a></p>
                            <p class="pb" (click)="navigatingToSearchedProducts(Categories[0]?.CategoryID, Categories[0]?.CategoryName)">
                                <a>Products</a>
                            </p>


                            <p class="pb"><a routerLink="/About">Vision & Mission</a></p>
                        </div>
                        <div class="col-lg-6">
                            <p class="pb"><a routerLink="/Careers">Careers</a></p>
                            <!--<p class="pb"><a routerLink="/About">Leader Ship</a></p>-->
                            <p class="pb"><a routerLink="/Certificates">Our Certificates</a></p>
                            <p class="pb"><a routerLink="/Contact">Contact Now</a></p>
                            <p class="pb"><a routerLink="/Productseo">SiteMap</a></p>
                        </div>

                    </div>

                </div>

                <div class="col-lg-3 mobile contact">
                    <a href="/ContactUs" class="">
                        <h3>Contact Us</h3>
                    </a>

                    <address>
                        <div style="line-height:2">
                            Plot No: 60, Main
                            RCC Building, Nacharam
                            Tech Park, Hyderabad,
                            Telangana, India-76.
                        </div>
                        <!--Azolechem<br />-->
                        <div style="line-height:2"><a href="tel:+917331143604" target="_blank"><i class="fa fa-phone"></i>&nbsp;+91 7331143604</a></div>
                        <!--<div><a href="tel:+917331143603" target="_blank"><i class="fa fa-phone"></i>&nbsp;+91 7331143603</a></div>-->
                        <div style="line-height:2">
                            <a href="mailto:info@azolechem.com" target="_blank">
                                <i class="fa fa-envelope"></i>&nbsp;
                                info&#64;azolechem.com
                            </a>
                        </div> <!--<div style="line-height:2">
                            <a href="mailto:sales@azolechem.com" target="_blank">
                                <i class="fa fa-envelope"></i>&nbsp;
                                sales&#64;azolechem.com
                            </a>
                        </div>-->
                    </address>



                    <ul class="social_links">
                        <li><a href="https://www.facebook.com/people/Azole-Chem-Private-Limited/61558462372150"><i class="fab fa-facebook-f icon_"style="color:white;"></i></a></li>
                        <li><a href="https://twitter.com/AzoleChem"><i class="fab fa-twitter icon_"style="color:white;"></i></a></li>
                        <li><a href="https://www.instagram.com/azolechem"><i class="fab fa-instagram icon_"style="color:white;"></i></a></li>
                        <li><a href="https://in.linkedin.com/company/azole-chem-pvt-ltd"><i class="fab fa-linkedin-in icon_"style="color:white;"></i></a></li>
                    </ul>




                </div>


            </div>


            <hr />
            <div class="row">
                <div class="col-lg-6 ">
                    <div class="rights">
                        <i class="far fa-copyright"style="color:white;"></i>
                        All rights are reserved by Azolechem
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="powered">
                        Powered by <a href="https://gagri.net/" target="_blank">
                            <img class="gagri2" src="../../../assets/Images/gagrilogo.webp" alt="Gagri" />
                        </a>
                    </div>
                </div>
            </div>




        </div>






    </footer>






<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>
