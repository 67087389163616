


<div id="about" class="about-area ptb-100"style="    padding-bottom: 0;">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Company Profile</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:white;">Company Profile</li>
            </ul>
        </div>
    </nav>
    <section role=slider>
        <div class="container">
            <h1>Welcome To Azolechem</h1>
            <p>
                We form alliances,
                    Azole Chem provides complete catalog goods and services in the pharmaceutical, agrochemical,
                    and other related industries.
               The company was founded on a solid foundation and has since grown on trust.<br />
                Azole Chem offers premium collaboration for the goods and services that improve our clients' and business
                partners' capacity and productivity in areas that are essential to their success.
            </p>
        </div>
        <div class="slider-contain">
            <div class="small-features">
                <div class="small">
                    <span>We love what we do.</span>
                </div>

                <!--<img src="../../../assets/Images/home1.webp" />-->
                <div class="small" style="background-image: url(../../../assets/Images/home1.webp)"></div>
            </div>
            <div class="main-feature" style=" background-image:url(../../../assets/Images/home.webp)">
            </div>
        </div>
        <div class="container">
            <p>
                We have the ability to steer our business's operations through ingenuity, inventiveness, and progressivism in
                terms of procedures, tools, and operational procedures.
                    In order to achieve "Superior Customer Satisfaction,"
                    we feel that quality deliverance is essential in every part of our business process, even as we strive for
                    perfection in our services.
                 <br />In our operations, we also adhere to a number of quality- and knowledge-based protocols and methods.
                To purchase specialized construction bricks and screening decks via a one-time payment method. Bench chemists are able to get quotations for our products and browse our library of various chemicals.
            </p>
        </div>
    </section>
</div>
<app-how-we-work></app-how-we-work>

<app-partner></app-partner>
<!--<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>-->
<!--<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact>-->
