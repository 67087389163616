import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
 
  
})
export class GeneralService {
  HomeURL: any;
  url: any;
  urlname: any;
  categoryId: any = []
  prodData: any = []
  private client = new BehaviorSubject<any>('');
  clientData = this.client.asObservable();
  public categoryIDarr = new BehaviorSubject<any>([]);
  public productArr = new BehaviorSubject<any>([]);

  constructor(public http: HttpClient) {
    //this.http.get('../assets/WebService.json').subscribe((data: any) => {
    //  this.HomeURL = data.Webservice;
    //});
  }

  getClientData(data: any) {
    this.client.next(data);
  }
  /*
   Below method is for getting category id from throug services
  */
  getCategoryid() {
    return this.categoryIDarr.asObservable();
  }
  /*
    Below method is for setting category id from throug services
   */
  setCategoryid(data: any) {
    this.categoryId = []
    this.categoryId.push(...data)
    this.categoryIDarr.next(this.categoryId)
  }
  /**
   * clear category Id
   */
  clearCategoryid() {
    this.categoryId = []
    this.categoryIDarr.next(this.categoryId)
  }

  /*
   Below method is for getting Products from throug services
  */
  getProducts() {
    return this.productArr.asObservable();
  }

  /*
   Below method is for setting Products from throug services
  */
  setProducts(data: any) {
    this.prodData = []
    this.prodData.push(...data)
    this.productArr.next(this.prodData)
  }


  Alertsuccess(title:any, text:any, Icon:any) {
    Swal.fire({
      title: title,
      html: text,
      icon: Icon,
      confirmButtonText: 'Ok'
    })
    }
    Alertwarning(title: any, text: any, Icon: any) {
        Swal.fire({
            title: title,
            html: text,
            icon: Icon,
            confirmButtonText: 'Ok'
        })
    }
    Alerterror(title: any, text: any, Icon: any) {
        Swal.fire({
            title: title,
            html: text,
            icon: Icon,
            confirmButtonText: 'Ok'
        })
    }

    ShowAlert(title: any, text: any, Icon: any) {
        Swal.fire({
            title: title,
            html: text,
            icon: Icon,
            confirmButtonText: 'Ok'
        })
    }
  ShowAlert1(title: any, text: any, type: any) {
    Swal.fire({
      title: title,
      html: text,
      //icon: type,
      confirmButtonText: 'Print',
      //confirmButtonText1: 'Cancel'
    })
  }
  //Global Method to retrieve data 
  //GetData(url: any) {
  //  return new Promise((resolve, reject) => {

  //    this.http.get('../assets/WebService.json').subscribe((data: any) => {

  //      this.urlname = data.Webservice;
  //      this.url = this.urlname + url
  //      this.http.get(this.url).subscribe(data => {

  //        resolve(data)
  //      }, err => {
  //        reject(err)
  //      })
  //    }, err => {
  //      reject(err)
  //    })
  //  });
  //}
  //  PostData(url: string, PostParam: any) {     
  //  return new Promise((resolve, reject) => {

  //    this.http.get('../assets/WebService.json').subscribe((data: any) => {

  //      this.urlname = data.Webservice;
  //      url = data.Webservice + url
  //      this.http.post(url, PostParam).subscribe(data => {

  //        resolve(data)
  //      }, err => {
  //        reject(err)
  //      })
  //    }, err => {
  //      reject(err)
  //    })
  //  });
  //  }
  // PostData2(url:any, PostParam:any, PostParam2:any) {
    
  //  return new Promise((resolve, reject) => {
  //    this.http.get('../assets/WebService.json').subscribe((data: any) => {
  //      url = data.Webservice + url
  //      this.http.post(url, PostParam, PostParam2).subscribe(data => {
  //        resolve(data)
  //      }, err => {
  //        reject(err)
  //      })
  //    }, err => {
  //      reject(err)
  //    })
  //  });
  //  }


    GetData(url: any) {
        return new Promise((resolve, reject) => {
            this.http.get('../assets/WebService.json').subscribe((data: any) => {
               
                this.urlname = data.Webservice;
                this.url = this.urlname + url
                this.http.get(this.url).subscribe(data => {

                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }
    PostData(url: any, PostParam: any) {
        return new Promise((resolve, reject) => {
       
            this.http.get('../assets/WebService.json').subscribe((data: any) => {
            
                this.urlname = data.Webservice;
                url = data.Webservice + url
                this.http.post(url, PostParam).subscribe(data => {
                    
                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }
    PostData2(url: any, PostParam: any, PostParam2: any) {
       
        return new Promise((resolve, reject) => {
            this.http.get('../assets/WebService.json').subscribe((data: any) => {
                url = data.Webservice + url
                this.http.post(url, PostParam, PostParam2).subscribe(data => {
                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }
    

}
