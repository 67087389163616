<!-- Small rounded button to open the chatbot -->
<button class="chatbot-toggle" (click)="toggleChat()">Hi</button>

<!-- Chatbot container -->
<div class="chatbot-container" [class.hidden]="!isChatOpen">
    <div class="chatbot-header">
        <span>Chat with Us</span>
        <button class="close-chatbot" (click)="toggleChat()">X</button>
    </div>

    <div class="chatbot-messages">

        <img src="../../../assets/Images/AzolelogoLogo_TM.svg" />
        <p>
            <span class="highlight"> providing fine and specialty chemicals</span> across diverse industries such as pharmaceuticals, crop science, biotechnology, healthcare, and chemical companies.
        </p>


        <div class="message" *ngFor="let msg of messages">
            <div class="message-text">{{ msg.text }}</div>
        </div>
    </div>

    <div class="chatbot-input">
        <input [(ngModel)]="userMessage"
               (keydown.enter)="sendMessage()"
               placeholder="Type a message"
               type="text" />
        <button (click)="sendMessage()">Send</button>
    </div>
</div>
