<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Highly Creative Solutions</h4>
                        <h2>About <span>Strategy</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Creative Design</li>
                        <li><i class="fa fa-check"></i>Retina Ready</li>
                        <li><i class="fa fa-check"></i>Responsive Design</li>
                        <li><i class="fa fa-check"></i>Modern Design</li>
                        <li><i class="fa fa-check"></i>Awesome Design</li>
                        <li><i class="fa fa-check"></i>Digital Marketing & Branding</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
