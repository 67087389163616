import { Component } from '@angular/core';

@Component({
  selector: 'app-request-for-quote',

  templateUrl: './request-for-quote.component.html',
  styleUrls: ['./request-for-quote.component.scss']
})
export class RequestForQuoteComponent {

}
