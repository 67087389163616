<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Products</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>

                </li>
                <li class="breadcrumb-item" style="color:white;">SiteMap</li>
                <li class="breadcrumb-item" style="color:white;"></li>
            </ul>
        </div>
    </nav><br />

        <div class="container">

            <div>
                <h2 class="headerss">Sitemap Links</h2> 
            </div><br />
                <div class="row">
                      <div class="col-lg-6" *ngFor="let item of seolinkdata">
                          <div class="linkss" (click)="seolink(item.Seolinkname)">  {{item.Title}} </div>

                      </div>
                </div>


        </div>
</div>
