
<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>LedgerReport</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:white;"><a routerLink="/Cart">LedgerReport</a></li>

            </ul>
        </div>
    </nav><br /><br /> 


    <p-table #dt [columns]="cols" [value]="Reportdata" [resizableColumns]="true" styleClass="p-datatable-gridlines" [responsive]="true" [rows]="17" [paginator]="true">

        <ng-template pTemplate="caption">
            <div class="row">
                <div class="col-lg-3" style="text-align: left;margin-bottom: 0;">
                    <i class="fa fa-download" pTooltip="Export to excel" tooltipPosition="top"
                       aria-label="" (click)="dt.exportCSV()" style="font-size: 20px; color:#3e50b4; cursor:pointer;margin-left:8px;"></i>
                </div>
                <div class="col-lg-9" style="text-align: right;margin-bottom: 0;">
                    {{dt.filteredValue?dt.filteredValue.length:dt.totalRecords}} of {{dt.totalRecords}}
                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                    <input type="text" class="global_search search" pInputText size="50" placeholder="Global Filter"
                           (input)="dt.filterGlobal($any($event.target).value, 'contains')" style="width:auto">
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
                <col *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
            </colgroup>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                    <span>{{col.header}}</span>
                    <p-sortIcon [field]="col.field" style="height:10px;"></p-sortIcon>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns" [ngSwitch]="col.field" class="border-right">
                    <span class="a_g99" *ngSwitchDefault style="padding-left:3%">{{rowData[col.field]}}</span>
                </td>
            </tr>
        </ng-template>


    </p-table>
</div>

   
