<div class="topnav">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 topnav1">
                <ul>
                    <li style="float:left">
                        <a href="tel:+917331143604" target="_blank" style="color:white; font-size:15px;cursor:pointer;">
                            <i class="fas fa-phone" style="font-size:13px;"></i>
                            +91 7331143604  &nbsp;
                        </a>
                    </li><li></li>
                         <li>
                            <a class="" href="mailto:info@azolechem.com" target="_blank" style="color: white; margin-left: 8px; cursor: pointer; font-size: 15px; float: left ">
                                 <i class="fa fa-envelope"
                                    aria-hidden="true"></i>&nbsp; info&#64;azolechem.com
                             </a>&nbsp;
                         </li>
                </ul>
             
            </div>


            <div class="col-lg-6">
                <div class="input-group">
                    <span class="input-group-addon">
                        <i class="fa fa-search"></i>
                    </span>
                    <input type="text" style="display:none" autocomplete="username">
                    <input type="password" style="display:none" autocomplete="new-password">
                    <input type="search" style="border-radius:5px" placeholder="Search by Chemical Name/ CAS No/ Catalog No/ Category" class="search" #searchInput (input)="getProducts(searchInput.value)" />

                </div>
                <div class="search-results-popup" *ngIf="searchInput.value && category !== null">
                    <!-- Loader element -->
                    <div class="loader" *ngIf="loading"></div>

                    <div class="card" *ngIf="category && category.length > 0" style="cursor:pointer">
                        <div>
                            <ul class="list-group search-results-container">
                                <li class="list-group-item" *ngFor="let item of category"
                                    (click)="navigatingToSearchedProduct(item)">{{ item.Products }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="no-results" *ngIf="category && category.length === 0" style="margin-top:-25px;color:black">
                        No products matched
                    </div>
                </div>


            </div>
            <div class="col-lg-2 topnav1">
                <ul>
                    <li style=" display: inline-block; float: right">
                        <a href="https://api.whatsapp.com/send?phone=917331143604&text=%20Hello%20Azolechem%20Team&source=&data=%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20target=" target="_blank" style="color:white;">
                            <i class="fab fa-whatsapp iconss"></i>
                        </a>
                    </li>
                    <li style=" display: inline-block; float: right">
                        <a href="https://www.facebook.com/people/Azole-Chem-Private-Limited/61558462372150" target="_blank" style="color: white;    ">
                            <i class="fab fa-facebook-f  iconss"></i>
                        </a>
                    </li>
                    <li style=" display: inline-block; float: right">
                        <a href="https://twitter.com/AzoleChem" target="_blank" style="color: white;    ">
                            <i class="fab fa-twitter iconss"></i>
                        </a>
                    </li>
                </ul>

                <!--<a href="" style="color: white;    float: inline-end;">
                  <i class="fab fa-shopping-cart"></i>
                </a>
                <a href="" style="color: white;    float: inline-end;">
                  <i class="fab fa-download"></i>
                </a>-->
            </div>
        </div>
    </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light "
     [class.active]="classApplied"style="background-color:white;">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <!--<span>Dress</span>Box-->
            <img src="../../../../assets/Images/Azole logo Logo_TM.svg" class="dresslogo" />
            <!--<img src="../../../../assets/Images/logo1.jpeg" class="dresslogo" />-->
            <!--<img src="../../../assets/Images/logo1.p" class="dresslogo" />-->
        </a>
        <div class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu" [ngClass]="{'class-name': classApplied}">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </div>


        <div class="collapse navbar-collapse" id="navbarSupportedContent" style="    margin: 1% 1% 1% 1%;">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item">
                    <div class="dropdown">
                        <p class="dropbtn" style="cursor:pointer;">About Us <i class="fa fa-caret-down" style="color:black"></i></p>
                        <div class="dropdown-content" style="margin-top:4px">
                            <a routerLink="/Companyprofle" title="Learn more about Azole">Company Profile</a>
                            <a (click)="about()">Vision & Mission With Core Values</a>
                            <!--<a routerLink="/Leadership"> Leadership Team</a>-->
                            <a routerLink="/Certificates">Our Certificates</a>

                        </div>
                    </div>
                </li>
                <!--<li class="nav-item">
                <div class="dropdown">
                    <p class="dropbtn" style="cursor:pointer;">Products <i class="fa fa-caret-down" style="color:black"></i></p>
                    <div class="dropdown-content" style="margin-top: -17px;">
                        <a *ngFor="let category of Categories" (click)="navigatingToSearchedProducts(category.CategoryID, category.CategoryName)">{{category.CategoryName}}</a>
                    </div>
                </div>
            </li>-->
                <!--<li class="nav-item">
                <div class="dropdown" (mouseenter)="showCategories = true" (mouseleave)="showCategories = false">
                    <p class="dropbtn" style="cursor:pointer;">Products <i class="fa fa-caret-down" style="color:black"></i></p>
                    <div class="dropdown-content" *ngIf="showCategories">
                        <a *ngFor="let category of Categories" (click)="navigatingToSearchedProducts(category.CategoryID, category.CategoryName)">{{category.CategoryName}}</a>
                    </div>
                </div>
            </li>-->
                <li class="nav-item">
                    <div class="dropdown">
                        <div class="dropbtn" (mouseenter)="GetCategories()" style="color:black;cursor:pointer">
                            Products &nbsp;<i class="fa fa-caret-down"></i>
                            <div class="dropdown-content">
                                <div *ngFor="let link of Categories;">
                                    <a (click)="navigatingToSearchedProducts(link.CategoryID,link.CategoryName)">
                                        {{ link.CategoryName }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="nav-item" (click)="careers()"><span class="nav-link">Careers</span></li>
                <li class="nav-item"><span class="nav-link"><a href="https://azolechemcs.com/" target="_blank" class="text-black">R & D<sup  class="neww">New</sup> </a></span></li>
                <li class="nav-item" (click)="contact()"><span class="nav-link">Contact Us</span></li>
                <li class="nav" *ngIf="this.loginDetails==undefined">
                    <div class="nav-item">
                        <span class="nav-link" (click)="Login()">Login</span>
                    </div>
                </li>
                <li class="nav-item">
                    <span class="nav-link" (click)="cartdedails()">
                        <i class="fa fa-cart-plus cart-icon" aria-hidden="true" style="color: #295fa9;"> ({{countReturn()}})</i>

                    </span>
                </li>
                <li class="nav-item" *ngIf="this.loginDetails!=undefined">
                    <div class="dropdown">
                        <p class="dropbtn circle" style="text-align:center">
                            {{ loginDetails[0]?.UserName ? loginDetails[0]?.UserName.charAt(0) : '' }}
                        </p>

                        <div class="dropdown-content">
                            <a routerLink="/Profile">My Profile</a>
                            <a routerLink="/Useraddress">Address</a>
                            <!--<a routerLink="/Ledgerreports">Ledger Reports</a>-->
                            <a routerLink="/MyOrders">My Orders</a>
                            <a routerLink="/Favourite">My Favorites</a>
                            <a *ngIf="this.loginDetails!=undefined" (click)="logout()">Logout</a>
                        </div>
                    </div>
                </li>
                <!--<li class="nav-item" routerLink="/Registration"><span class="nav-link">Registration</span></li>-->
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item">
                    <div class="dropdown">
                        <p class="dropbtn" style="cursor:pointer;">About Us <i class="fa fa-caret-down" style="color:black"></i></p>
                        <div class="dropdown-content">
                            <a routerLink="/Companyprofle" title="Learn more about Azole">Company Profile</a>
                            <a (click)="about()">Vision & Mission With Core Values</a>
                            <!--<a routerLink="/Leadership"> Leadership Team</a>-->
                            <a routerLink="/Certificates">Our Certificates</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <div class="dropdown">
                        <p class="dropbtn" style="cursor:pointer;">Products <i class="fa fa-caret-down" style="color:black"></i></p>
                        <div class="dropdown-content" (mouseover)="showCategories = true" (mouseleave)="showCategories = false">
                            <a *ngFor="let category of Categories" (click)="navigatingToSearchedProducts(category.CategoryID,category.CategoryName)">{{category.CategoryName}}</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item" (click)="careers()"><span class="nav-link">Careers</span></li>
                <li class="nav-item"><span class="nav-link"><a href="https://azolechemcs.com/" target="_blank" class="text-black">R & D<sup class="neww">New</sup> </a></span></li>
                <li class="nav-item" (click)="contact()"><span class="nav-link">Contact Us</span></li>
                <li class="nav" *ngIf="this.loginDetails==undefined">
                    <div class="nav-item">
                        <span class="nav-link" (click)="Login()">Login</span>
                    </div>
                </li>
                <li class="nav-item">
                    <span class="nav-link" (click)="cartdedails()">
                        <i class="fa fa-cart-plus" aria-hidden="true" style="color: orangered;"></i>({{countReturn()}})
                    </span>
                </li>
                <li class="nav-item" *ngIf="this.loginDetails!=undefined">
                    <div class="dropdown" style="height:50px">
                        <p class="dropbtn circle" style="text-align:center;height:40px;color:white">
                            {{ loginDetails[0]?.UserName ? loginDetails[0]?.UserName.charAt(0) : '' }}
                        </p>
                        <div class="dropdown-content" style="    margin-left: 100%;margin-top:-39%">

                            <a (click)="address()">Address</a>
                            <!--<a routerLink="/Ledgerreports">Ledger Reports</a>-->
                            <a (click)="orders()">My Orders</a>
                            <a (click)="favorites()">My Favrotes</a>
                            <a *ngIf="this.loginDetails!=undefined" (click)="logout()">Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
