import { Component } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-productseo',
  templateUrl: './productseo.component.html',
  styleUrls: ['./productseo.component.scss']
})
export class ProductseoComponent {
    seolinkdata: any = [];

    constructor(public generalservice: GeneralService, private router: Router) {

    }


    ngOnInit() {
        this.GetSeoLink();
    }

    GetSeoLink() {
        debugger
        var url = 'api/Azole/Get_Seo_Data_FromClient';
        this.generalservice.GetData(url).then((data: any) => {
            debugger
            this.seolinkdata = data;
        });
    }

    seolink(Seolinkname: any) {
        debugger
        this.router.navigate(['/products', Seolinkname ]);
    }

}
