import { Component } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-addaddress',
  standalone: true,
  imports: [],
  templateUrl: './addaddress.component.html',
 // styleUrl: './addaddress.component.scss'
})
export class AddaddressComponent {
 // public form: FormGroup;
  arr: any = [];
  districtdata: any = [];
  deptList: any;
  flag: any;
  Statedata: any = [];
  profiledata: any;
  Citydata: any = [];
  Name: any;
  mobile: any;
  HNo: any;
  Address: any;
  Landmark: any;
  City: any;
  State: any;
  Pincode: any;
  deptList1: any;
  deptList2: any; bloodgroupdata: any = [];
  deptList3: any;
  subDropdownStyle: { [key: string]: string } = {};
  isMouseOverSubDropdown: boolean = false;
  showDropdownContent = false;
  selectedBloodgroup: string = '';
  selectedGender: string | null = null;
  LoginDetails1: any; LoginDetails: any;
 // selectedpast: string;
  Lastdonatedate: any;
  Status: boolean = true;
  Mobile: any;
  RegID: any;
  logindata: boolean;
    schooldata: any = [];
    category: any = [];
  subtasklist: any;
  ButtonText: any;
  selectedOption: any;
  ID: any;
    users: any;
    ddd: any;

  constructor(public genralservice: GeneralService, public http: HttpClient, public fb: FormBuilder, public router: Router,) {

 
    localStorage.removeItem("keepsignin");
    this.LoginDetails1 = localStorage.getItem("LoginDetails");
    this.LoginDetails = JSON.parse(this.LoginDetails1);

    if (this.LoginDetails != null) {
      this.logindata = true;
      this.Name = this.LoginDetails[0].Name
      this.mobile = this.LoginDetails[0].Mobile
      //this.HNo = this.LoginDetails[0].HNo
      //this.Address = this.LoginDetails[0].Address
      //this.City = this.LoginDetails[0].City
      //this.State = this.LoginDetails[0].State
      //this.Pincode = this.LoginDetails[0].Pincode
      //this.Landmark = this.LoginDetails[0].Landmark
    } else {
      this.logindata = false;
    }
  }

  ngOnInit(): void {
   // this.getschool();
     this.getregdetails();
  }

  //getschool() {
 
  //  this.arr = []
  //  this.arr.push({
  //    RegID: '2',
  //    TokenID: 'D864AE02-4029-4014-9B11-7D8609DD1622',
  //  })
  //  var UploadFile = new FormData()
  //  UploadFile.append("Param", JSON.stringify(this.arr));
  //  UploadFile.append("Flag", '4');
  //  var url = "api/DressBox/School_Master";
  //  this.genralservice.PostData(url, UploadFile).then(data => {
 
  //    this.schooldata = data;
  //  }, err => {
  //    this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

  //  })
  //}


  getregdetails() {
 
    this.arr = []
    this.arr.push({
     
    })
    var UploadFile = new FormData()
    UploadFile.append("Param", JSON.stringify(this.arr));
    UploadFile.append("Flag", '4');
    var url = "api/Azole/Address_Master";
    this.genralservice.PostData(url, UploadFile).then(data => {
  

        this.category = data;

      

    }, err => {
      this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

    })
  }


    //getdata() {
 
    //    for (var i = 0; i < this.schooldata.length; i++) {
 
    //        this.users = this.schooldata[i];
    //        this.ddd = this.users.HNo
    //        this.Address = this.users.Address
    //        this.City = this.users.City
    //        this.State = this.users.State
    //        this.Pincode = this.users.Pincode
    //    }
    //}

  getSelectedschool(val: any) {

    this.ID = val
  }
  InsertAdderss(Name: any, mobile: any, HNo: any, Address: any, Landmark: any, City: any, State: any, Pincode: any) {
    
    this.arr = [{
      SID: this.selectedOption,
      UID: this.LoginDetails[0].RegID,
      HNo: HNo,
      Address: Address,
      Landmark: Landmark,
      City: City,
      State: State,
      Pincode: Pincode

    }];

    var uploadfile = new FormData();
    uploadfile.append("Param", JSON.stringify(this.arr));
    uploadfile.append("Flag", '1');

    var url = 'api/Azole/Address_Master';

    this.genralservice.PostData(url, uploadfile).then(data => {
      if (data == "SUCCESS") {
      
        if (this.flag == 1) {
          //this.genralservice.ShowAlert("ERROR", 'You have successfully signed in as a Address form', 'error');
        } else {
          this.genralservice.ShowAlert("SUCCESS", 'You have successfully added the Address', 'success');
          //this.router.navigate(['/Donersform']).then(() => { window.location.reload(); });
          this.router.navigate(['/navbar']);


        }
      }
    });
  }

  Back() {
    this.router.navigate(['/']);
    }


    plus() {
        this.router.navigate(['/Address']);
    }
  //ngAfterViewInit() {
  //  document.getElementById('preloader').classList.add('hide');
  //}
}


