<div id="about" class="about-area ptb-100">

    <div class="container h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-lg-12 col-xl-11">
                <div class="card text-black" style="border-radius:0;border:none;box-shadow:none">
                    <div class="card-body p-md-5">
                        <div class="row justify-content-center">
                            <div class="col-md-12 col-lg-6 col-xl-7 order-2 order-lg-1">

                                <!--<p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>-->

                                <form class="mx-1 mx-md-4" [formGroup]="form">
                                    <div class="row">
                                        <div class="col-lg-6 d-flex flex-row align-items-center mb-4">
                                            <i class="fas fa-building fa-lg me-3 fa-fw" style="color:orangered"></i>
                                            <div class="form-outline flex-fill mb-0">
                                                <label class="form-label" for="form3Example1c">Company Name</label>
                                                <input type="text" formControlName="CompanyName" name="fullName" id="fullName" class="form-control" required />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 d-flex flex-row align-items-center mb-4">
                                            <i class="fas fa-user fa-lg me-3 fa-fw" style="color:orangered"></i>
                                            <div class="form-outline flex-fill mb-0">
                                                <label class="form-label" for="form3Example1c">Your Name</label>
                                                <input type="text" formControlName="UserName" name="fullName" id="fullName" class="form-control" required />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 d-flex flex-row align-items-center mb-4">
                                            <i class="fas fa-envelope fa-lg me-3 fa-fw" style="color:orangered"></i>
                                            <div class="form-outline flex-fill mb-0">
                                                <label class="form-label" for="form3Example3c">Your Email</label>
                                                <input type="text" formControlName="EMailID" name="Email" id="Email" class="form-control" (input)="CheckEmail()" required />
                                                <small class="text-danger" *ngIf="form.get('EMailID').touched">
                                                    <span *ngIf="form.get('EMailID').hasError('required')">Email is required</span>
                                                    <span *ngIf="form.get('EMailID').hasError('email')">Invalid email format</span>
                                                    <span *ngIf="checkemail==12">Email Exist</span>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <button class="btn1" (click)="SendOtpForMobile()" [disabled]="isButtonDisabled1">Send OTP</button>
                                        </div>


                                        <div class="col-lg-6 d-flex flex-row align-items-center mb-4">
                                            <i class="fas fa-key fa-lg me-3 fa-fw" style="color:orangered"></i>
                                            <div class="form-outline flex-fill mb-0">
                                                <label class="form-label" for="form3Example3c">OTP</label>
                                                <input formControlName="OTP" class="form-control Caps" placeholder="Enter Otp" type="text" maxlength="4">
                                            </div>
                                        </div>



                                        <div class="col-lg-6 d-flex flex-row align-items-center mb-4">
                                            <i class="fas fa-phone fa-lg me-3 fa-fw" style="color:orangered"></i>
                                            <div class="form-outline flex-fill mb-0">
                                                <label class="form-label" for="form3Example3c">Your Mobile Number</label>
                                                <input type="text" formControlName="Phone1" name="Phone" id="Phone" class="form-control" (input)="CheckMobileNo()" maxlength="10" required />
                                                <small class="text-danger" *ngIf="form.get('Phone1').touched">
                                                    <span *ngIf="form.get('Phone1').hasError('required')">Mobile is required</span>
                                                    <span *ngIf="checkNo==10">Mobile Number Exist</span>
                                                </small>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 d-flex flex-row align-items-center mb-4">
                                            <i class="fas fa-city fa-lg me-3 fa-fw" style="color:orangered"></i>
                                            <div class="form-outline flex-fill mb-0">
                                                <label class="form-label" for="form3Example3c">Your city</label>
                                                <input type="text" formControlName="City" name="fullName" id="fullName" class="form-control" required />
                                            </div>
                                        </div>

                                        <div class="col-lg-6 d-flex flex-row align-items-center mb-4">
                                            <i class="fas fa-lock fa-lg me-3 fa-fw" style="color:orangered"></i>
                                            <div class="form-outline flex-fill mb-0">
                                                <label class="form-label" for="form3Example4c">Password</label>
                                                <input type="{{ passwordshow ? 'text' : 'password' }}" formControlName="PWD" name="password" id="password" class="form-control" required />
                                                <span class="toggle-password" (click)="togglePasswordVisibility()">
                                                    <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                                                </span>

                                                <small class="text-danger" *ngIf="form.get('PWD').touched">
                                                    <span *ngIf="form.get('PWD').hasError('required')">Password is required</span>
                                                    <span *ngIf="form.get('PWD').hasError('minlength')">Password isn't long enough, minimum of 6 characters</span>
                                                </small>
                                            </div>
                                        </div>




                                        <div class="col-lg-6 d-flex flex-row align-items-center mb-4">
                                            <i class="fas fa-user fa-lg me-3 fa-fw" style="color:orangered"></i>
                                            <div class="form-outline flex-fill mb-0">
                                                <label class="head_txt"> GST Treatment Name<span style="color:red;">*</span></label>
                                                <div class="form-group mt-n2">
                                                    <p-autoComplete [suggestions]="BranchdropList" (completeMethod)="filterGroupsSingle4($event)" dataKey='Id' field="GST_TreatmentName"
                                                                    formControlName="GST_TreatmentName" [dropdown]="true" [size]="30"
                                                                    placeholder="Search with GST_TreatmentName" [minLength]="1" [forceSelection]="true">
                                                        <ng-template let-autofillCustomers pTemplate="item">
                                                            <div class="card">
                                                                {{autofillCustomers.GST_TreatmentName}}
                                                            </div>
                                                        </ng-template>
                                                    </p-autoComplete>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showGSTNumberField" class="col-lg-6 d-flex flex-row align-items-center mb-4">
                                            <i class="fas fa-phone fa-lg me-3 fa-fw" style="color:orangered"></i>
                                            <div class="form-outline flex-fill mb-0">
                                                <label for="GSTNo">GST Number<span style="color:red">*</span></label>
                                                <input type="text" class="form-control" id="GSTNo" formControlName="GSTNo" placeholder="GSTNo" maxlength="15" />
                                                <small class="text-danger" *ngIf="form.get('GSTNo').invalid && (form.get('GSTNo').dirty || form.get('GSTNo').touched)">
                                                    <span *ngIf="form.get('GSTNo').hasError('required')">GST Number is required</span>
                                                    <span *ngIf="form.get('GSTNo').hasError('invalidGST')">Invalid GST Number</span>
                                                </small>
                                            </div>
                                        </div>


                                        <!--<div class="form-check d-flex justify-content-center mb-5">
        <div class="row">
            <div class="col-lg-1">
                <input class="form-check-input me-2" style="border:1px solid orangered" type="checkbox" value="" id="form2Example3c" />
            </div>
            <div class="col-lg-11">
                <label class="form-check-label" for="form2Example3">
                    I agree all statements in <a href="#!">Terms of service</a>
                </label>
            </div>

        </div>

    </div>-->


                                    </div>
                            </form>
                            <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit()" [disabled]="isButtonDisabled">Register</button>
                            </div>
                        </div>
                            <div class="col-md-12 col-lg-6 col-xl-5 d-flex align-items-center order-1 order-lg-2">
                                <br />
                                <img src="https://img.freepik.com/premium-vector/online-registration-illustration-design-concept-websites-landing-pages-other_108061-938.jpg"
                                     class="img-fluid img"style="" alt="Sample image">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

