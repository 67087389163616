import { Component, ViewChild, ElementRef, AfterViewInit, Renderer2,OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service'
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SeoService } from '../../seo.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var Swiper: any; //for swiper2 date21-11-2024
@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})

export class HomeOneComponent implements OnInit, AfterViewInit {

  

    @ViewChild('productContainer') productContainer!: ElementRef<HTMLDivElement>;
    arr: any = [];
    category: any = [];
    loading: boolean;
    ProductsLists: any;

    isScrolling = true;
    HomeUrl: any;
    seodata: any = [];
    seolinkdata: any = [];

    constructor(private seoService: SeoService, public router: Router, private viewportScroller: ViewportScroller, private generalservice: GeneralService, public http: HttpClient,) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            //this.HomeUrl = 'http://localhost:57391/'

        });
    }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.seoService.updateMetaTags('Home', '');
        
    }
    ngAfterViewInit(): void {
        this.initializeSwiper();
    }
   // start Swiper
    initializeSwiper() {
        // Initialize the Swiper
        const swiper2 = new Swiper('.mySwiper', {
            spaceBetween: 30,
            slidesPerView: 4,
            loop: true,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
            },
            speed: 2000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        //end swiper
        // Add hover effect to stop autoplay on hover
        const swiperSlides = document.querySelectorAll('.swiper-slide');
        swiperSlides.forEach(slide => {
            slide.addEventListener('mouseenter', () => {
                swiper2.autoplay.stop();
            });
            slide.addEventListener('mouseleave', () => {
                swiper2.autoplay.start();
            });
        });
    }
    // Stop scroll on mouse enter
    stopScroll() {
        this.isScrolling = false;
    }

    // Resume scroll on mouse leave
    startScroll() {
        this.isScrolling = true;
    }

    getProducts(searchValue: string) {
        if (!searchValue) {
            this.category = null; // Clear the category if searchValue is empty
            return; // Do nothing if searchValue is empty
        }

        const formData = new FormData();
        formData.append('Param1', searchValue);

        const url = 'api/Azole/Products_Search';
        this.generalservice.PostData(url, formData).then(
            (data) => {
                 
                this.category = data;
            },
            (err) => {
                this.generalservice.ShowAlert('ERROR', 'Something went wrong', 'error');
            }
        );
    }
    getCategoryDetails() {
        if (!this.category || this.category.length === 0) {
            return 'No products matched'; // Display this message if no results are found
        }

        let details = '';
        this.category.forEach((item, index) => {
            details += `Product ${index + 1}: ${item.Products}\n`;
        });

        return details;
    }
    GetAllProducts1() {

        var GetUrl = 'api/Azole/GetAllProductsNew2023';
        this.generalservice.GetData(GetUrl).then((data: any) => {
            ;
            debugger

            this.ProductsLists = data;
            //this.startAutoScroll();

        });
    }
    //navigatingToSearchedProduct(Productname: any) {
    //    debugger
    //    localStorage.removeItem('CRMID');
    //    this.router.navigate(['/Product/' + Productname]);
    //}


  

    navigatingToSearchedProduct(Productname: any) {
        debugger
        // Show the loader
        this.loading = true;

        // Clear the search results (category) immediately
        this.category = null;


        localStorage.setItem('CRMID', Productname)
        const formattedName = this.dynamicUrlFormatter(Productname.Name);

        // Navigate using both CAS_No and the formatted Name
        this.router.navigate(['/Product', Productname.Catalogue_Number, Productname.CAS_No, formattedName]).then(() => {



            // Reload the page
            window.location.reload();
        }).catch(error => {
            console.error('Navigation to product page failed:', error);
        }).finally(() => {
            // Hide the loader
            this.loading = false;
        });
    }
    // Methods to show and hide the loader


    private dynamicUrlFormatter(productName: string): string {
        return productName
            .trim() // Remove leading/trailing whitespace
            .replace(/\s+/g, '-') // Replace spaces with hyphens
            .replace(/[^a-zA-Z0-9-]+/g, '') // Remove non-alphanumeric characters (except hyphens)
            .replace(/-+/g, '-') // Remove consecutive hyphens
            .replace(/^-|-$/g, ''); // Remove leading or trailing hyphens
    }
    navigatetoproduct(Productname: any) {
        this.loading = true;

        // Clear the search results (category) immediately
        this.ProductsLists = null;


        localStorage.setItem('CRMID', Productname)
        const formattedName = this.dynamicUrlFormatter(Productname.Name);

        // Navigate using both CAS_No and the formatted Name
        this.router.navigate(['/Product', Productname.Catalogue_Number, Productname.CAS_No, formattedName]).then(() => {



            // Reload the page
            window.location.reload();
        }).catch(error => {
            console.error('Navigation to product page failed:', error);
        }).finally(() => {
            // Hide the loader
            this.loading = false;
        });
    }

   

}
