<footer>
    <div class="image" style="background-image:url(https://images.unsplash.com/photo-1416339684178-3a239570f315?dpr=2&auto=format&fit=crop&w=1500&h=1125&q=80&cs=tinysrgb&crop=);"></div>

    <div class="contain">

        <h1 class="our">Our Clients</h1>
        <div class="slider">
            <input type="radio" name="slider" title="slide1" checked="checked" class="slider__nav" />
            <input type="radio" name="slider" title="slide2" class="slider__nav" />
            <input type="radio" name="slider" title="slide3" class="slider__nav" />
            <input type="radio" name="slider" title="slide4" class="slider__nav" />
            <div class="slider__inner">
                <div class="slider__contents">
                    <div class="row">
                        <div class="col-lg-3"> <img src="../../../assets/Images/Clients/aragon.webp"> </div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/chemved.webp"></div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/civent.webp"></div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/drreddys.webp"></div>

                    </div>
                    <!--<i class="slider__image fa fa-codepen"></i>
                    <h2 class="slider__caption">codepen</h2>
                    <p class="slider__txt">Lorem ipsum dolor sit amet, consectetur adipisicing elit. At cupiditate omnis possimus illo quos, corporis minima!</p>-->
                </div>
                <div class="slider__contents">
                    <div class="row">
                        <div class="col-lg-3"> <img src="../../../assets/Images/Clients/firmus.webp"> </div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/hetero.webp"></div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/laxaj.webp"></div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/syngene.webp"></div>

                    </div>
                </div>
                <div class="slider__contents">
                    <div class="row">
                        <div class="col-lg-3"> <img src="../../../assets/Images/Clients/aragon.webp"> </div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/chemved.webp"></div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/civent.webp"></div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/drreddys.webp"></div>

                    </div>
                </div>
                <div class="slider__contents">
                    <div class="row">
                        <div class="col-lg-3"> <img src="../../../assets/Images/Clients/firmus.webp"> </div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/hetero.webp"></div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/laxaj.webp"></div>
                        <div class="col-lg-3"><img src="../../../assets/Images/Clients/syngene.webp"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
