// seo.service.ts
import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(private titleService: Title, private metaService: Meta) { }

    updateMetaTags(productName: string, CatalogueNumber: string) {
        this.titleService.setTitle(`${productName} - Azolechem`);
        this.metaService.updateTag({ name: 'description', content: 'info' });
    }
    updateMetaTags1(productName: string, productDescription: string, catalogueNumber: string) {
        // Construct the full title
        const fullTitle = `${productName} - ${productDescription} - Azolechem`;

        // Set the page title with a scrolling effect
        this.startScrollingTitle(fullTitle);

        // Update the meta description
        this.metaService.updateTag({
            name: 'description',
            content: `Explore ${productName}. ${productDescription}. Catalogue Number: ${catalogueNumber}. Only at Azolechem.`
        });
    }

    // Function to implement scrolling effect in the title
    startScrollingTitle(title: string) {
        let index = 0;
        const scrollSpeed = 300; // Adjust scroll speed (ms)

        const scrollTitle = () => {
            const displayTitle = title.substring(index) + ' | ' + title.substring(0, index);
            this.titleService.setTitle(displayTitle);

            index = (index + 1) % title.length; // Loop back when the end is reached
        };

        // Start the scrolling effect
        setInterval(scrollTitle, scrollSpeed);
    }





    // Method to dynamically update meta tags, canonical, keywords, and SEO links
    //updateMetaTags(productName: string, catalogueNumber: string, url: string, keywords: string, description: string) {
    //    // Set the page title dynamically
    //    this.titleService.setTitle(`${productName} - Azolechem`);

    //    // Update meta description
    //    this.metaService.updateTag({ name: 'description', content: description });

    //    // Update meta keywords
    //    this.metaService.updateTag({ name: 'keywords', content: keywords });

    //    // Update canonical URL
    //    const link: HTMLLinkElement = document.createElement('link');
    //    link.setAttribute('rel', 'canonical');
    //    link.setAttribute('href', url);
    //    document.head.appendChild(link);
    ////}





}
