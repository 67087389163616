<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Products</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>

                </li>
                <li class="breadcrumb-item" style="color:white;">Products/</li>
                <li class="breadcrumb-item" style="color:white;">{{this.SeoLinkContent123[0].Title}}</li>
            </ul>
        </div>
    </nav><br />
          <div class="container">
              <div class="row">
                  <div class="col-lg-3">
                      <div>
                          <img [src]="this.SeoLinkContent123[0].ProductImage ? HomeUrl + this.SeoLinkContent123[0].ProductImage : 'assets/Images/Azole logo Logo_TM.svg'"
                               class="card_image"
                               [alt]="this.SeoLinkContent123[0].Keywords || 'DB'" />


                      </div>

                  </div>

                  <div class="col-lg-9 d-flex align-items-center">
                      <h1 class="flex-grow-1">{{ this.SeoLinkContent123[0].Title }}</h1>
                      <button type="button" (click)="navigate(navlink)" class="lnav btn btn-primary">
                          Order now
                      </button>
                  </div>
              </div>
              <br />
              <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-9">
                      <p style="text-align: left;" [innerHTML]="SeoLinkContent[0]?.TitleContent"></p>
                      <br />
                      <p><b>Related Link</b></p>
                      <p style="color: deepskyblue; cursor: pointer;" (click)="seolink(SeoLinkContent123[0].RelatedLink1)">
                          {{ this.SeoLinkContent123[0].RelatedLink1 }}
                      </p>
                      <p style="color: deepskyblue; cursor: pointer;" (click)="seolink(SeoLinkContent123[0].RelatedLink2)">
                          {{ this.SeoLinkContent123[0].RelatedLink2 }}
                      </p>
                  </div>
              </div>

          </div>


</div>
