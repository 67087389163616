<section role=main>
    <ul>
        <li>
            <div class="pic" style="background-image: url(../../../assets/Images/whoweare.webp);">
            </div>
            <div class="text">
                <!-- 				<div class="circle"></div> -->
                <span>Who we are.</span>
                <p>Azolechem is a leading provider of integrated solutions in the chemicals industry, specializing in pharmaceuticals, agrochemicals, and related sectors. With a strong commitment to innovation, quality, and customer satisfaction, Azolechem has established itself as a trusted partner for businesses seeking reliable and efficient chemical products and services.</p>
            </div>
        </li>

        <li>
            <div class="pic" style="background-image: url(../../../assets/Images/Five.webp);">
            </div>
            <div class="text">
                <span>"Choosing Azolechem: Enhancing Value, Service, and Efficiency"</span>
                <p>"Five Strong Arguments for Selecting Azolechem as Your Value-Added Partner: Focused on Value, Exceptional Customer Service, Door Opener Policy, Robust Product Catalog, Continuous Achievement Record, and Supply Chain Optimization."</p>
            </div>
        </li>
        <li>
            <div class="pic" style="background-image: url(../../../assets/Images/why.webp);">
            </div>
            <div class="text">
                <!--<div class="circle"></div>-->
                <span>Why Azolechem</span>
                <p>Azolechem stands out as a premier partner in the chemicals industry due to its commitment to integrated solutions and customer-centric approach. Our comprehensive catalog covers a wide range of products and services tailored to pharmaceuticals, agrochemicals, and related sectors, ensuring that our clients receive the highest quality and most efficient solutions. </p>
            </div>
        </li>
        <!--<li>
          <div class="pic" style="background-image: url('https://images.unsplash.com/photo-1478357100796-dc5a1613bedf?dpr=1&auto=format&fit=crop&w=1500&h=1001&q=80&cs=tinysrgb&crop=');">
          </div>
          <div class="text">
            <span>Beautifully crafted.</span>
            <p>Our products are handcrafted and made to order. Each dongle is unique and has what we like to think of as “perfect imperfections.” Our aesthetic is modern and playful. We aren’t your traditional dongle company, and we couldn’t be happier about it.</p>
          </div>
        </li>-->

    </ul>
</section>
