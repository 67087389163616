import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})


export class CategoriesComponent {

}



//import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
//import { GeneralService } from '../../Services/generalservice.service';
//import { FormGroup, FormBuilder, FormsModule, Validators } from '@angular/forms'

//import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
//import Swal from 'sweetalert2';
//import { HttpHeaders } from '@angular/common/http';
//import { Directive, HostListener } from '@angular/core'


//import { Router, ActivatedRoute } from '@angular/router';
//import { CartService } from '../../Services/cart.service'
//@Component({
//  selector: 'app-categories',
//  standalone: true,
//  imports: [],
//  templateUrl: './categories.component.html',
//  styleUrl: './categories.component.scss'
//})
//export class CategoriesComponent {
//  alphabetvale: any;
//  HomeUrl: any;
//  enquirydata: any;
//  categoryId: any;
//  ProductListforFilter: any = []
//  ProductsList: any = []
//  public form: FormGroup;
//  personName: any;
//  contactNumber: any;
//  email: any;
//  comments: any;
//  cartitems = false;
//  cartData = [];
//  enquiryData = [];
//  pInfocartData: any
//  cartItemsCount: any;
//  ItemId: any;
//  itemSize: any;
//  selectedItemforCart: any = []
//  cartItemList: any = []
//  toast: any;
//  P_ID: any;
//  cartItemList1: any;
//  whatsAppProductURL: any;
//  token: any;
//  arr: any;
//  selectedcompanys: any;
//  selectedlocation: any;
//  selectedexperince: any;
//  searchcompany: any = [];
//  searchcomp: any;
//  JobCode: any;
//  searchlist: any = [];
//  Companyname: any;
//  pInfocartData1: any;
//  enquirydata1: any;
//  constructor(fb: FormBuilder, public generalService: GeneralService, public cartService: CartService, public http: HttpClient, public router: Router,) {
//    this.http.get('../assets/WebService.json').subscribe((data: any) => {
//      this.HomeUrl = data.Webservice;
//    });
//    debugger
//    this.pInfocartData1 = localStorage.getItem('cartData')
//    this.pInfocartData = JSON.parse(this.pInfocartData1)
//    this.enquirydata1 = localStorage.getItem('enquiryData')
//    this.enquirydata = JSON.parse(this.enquirydata1)
//    if (this.pInfocartData != null) {
//      this.cartData = this.pInfocartData
//    }

//    this.form = fb.group({
//      'personName': ['', Validators.compose([Validators.required])],
//      'contactNumber': ['', Validators.compose([Validators.required])],
//      'comments': [''],
//      'email': ['', Validators.compose([Validators.required])],
//    });
//    this.personName = this.form.controls['personName'];
//    this.contactNumber = this.form.controls['contactNumber'];
//    this.comments = this.form.controls['comments'];
//    this.email = this.form.controls['email'];
//    if (this.enquirydata != null) {
//      this.form.controls['personName'].setValue(this.enquirydata[0].PersonName);
//      this.form.controls['contactNumber'].setValue(this.enquirydata[0].PhoneNumber);
//      this.form.controls['email'].setValue(this.enquirydata[0].MailId);
//    }
//    console.log(this.pInfocartData)

//    var catdata = localStorage.getItem('catid')
//    if (catdata != null) {
//      this.categoryId = catdata
//      this.FilterList(catdata);

//    }
//    else {
//      this.categoryId = 1;
//      this.FilterList(1);
//    }
//    localStorage.removeItem('catid');
//  }

//  ngOnInit() {
//    debugger
//    this.cartService.getProducts().subscribe((res:any) => {
//      this.cartItemsCount = res.length;
//      this.cartItemList = res;
//    })
  
//  }
//  FilterList(id:any) {
//    debugger
//    if (id == 1) {
//      this.categoryId = 1
//      this.GetProductsBasedOnCategoryID(id)
//    }
//    else if (id == 2) {
//      this.categoryId = 2
//      this.GetProductsBasedOnCategoryID(id)
//    }
//    else if (id == 3) {
//      this.categoryId = 3
//      this.GetProductsBasedOnCategoryID(id)
//    }
//    else if (id == 4) {
//      this.categoryId = 4
//      this.GetProductsBasedOnCategoryID(id)
//    }
//    else {
//      this.categoryId = 5
//      this.GetProductsBasedOnCategoryID(id)
//    }
//  }

//  GetProductsBasedOnCategoryID(Id:any) {
//    debugger
//    var url = "api/Token/Gettoken";
//    this.generalService.GetData(url).then((data: any) => {

//      if (data && data.access_token) {
//        this.token = data.access_token;
//      }
//      var CategoryId = Id
//      var UploadFile = new FormData();
//      UploadFile.append("CategoryID", CategoryId);
//      var url = this.HomeUrl + "api/SCIKEM/GettingProductDetailsBasedOnCategoryID";
//      var accessToken = this.token; 
//      const headers = new HttpHeaders({
//        'Authorization': `Bearer ${accessToken}`
//      });
     
//      debugger
//      this.http.post(url, UploadFile, { headers }).subscribe((data: any) => {

//        debugger
//        this.searchcompany = data
//        this.ProductListforFilter = data


//      }, err => {
    
//      });
//    }); 
//  }



//  ProductInfo(CategoryName:any, ChemicalName:any, Cas:any) {
//    debugger
  
//    localStorage.setItem("ChemicalName", ChemicalName)
//    localStorage.setItem("CategoryName", CategoryName)
//    localStorage.setItem("Cas", Cas)
//    localStorage.setItem("cartData", JSON.stringify(this.cartData))
//    localStorage.setItem("Cattegoryid", this.categoryId);

//    this.router.navigate(['/Products', CategoryName, ChemicalName + '-' + Cas]);


//    const url = 'https://Scikem.com/Products/' + CategoryName + '/' + ChemicalName + '-' + Cas;
//  }



//  countReturn() {

//    this.cartService.getProducts().subscribe((res: any) => {

//      this.cartItemList = res.length;
//      return this.cartItemList
//    })
//    return this.cartItemList
//  }
//  Addtocart(item: any) {
//    debugger

//    this.cartService.getProducts().subscribe((res:any) => {
//      for (var i = 0; i < res.length; i++) {
//        this.P_ID = res[i].P_ID
//      }
     
//      return this.cartItemList1

//    })
//    this.Addtocart1(item);


//  }





//  Addtocart1(item: any) {

//    debugger

//    // this.cartItemList1 = this.cartItemList;

//    //for (var i = 0; i < this.cartItemList1.length; i++) {
//    // this.P_ID = this.cartItemList1[0].P_ID
//    // }
//    if (this.P_ID != item.P_ID) {
//      debugger
//      this.ItemId = 0;
//      this.itemSize = "";
//      this.selectedItemforCart.push({
//        P_ID: item.P_ID, Catalog_No: item.Catalog_No, ChemicalName: item.ChemicalName ? item.ChemicalName : "",
//        CatChemicalName: item.CatChemicalName,
//        Cas: item.Cas,
//        Pack1: item.Pack1,
//        Price1: item.Price1,
//        Pack2: item.Pack2,
//        Price2: item.Price2,
//        Pack3: item.Pack3,
//        Price3: item.Price3,
//        Pack4: item.Pack4,
//        Price4: item.Price4,
//        Pack5: item.Pack5,
//        Price5: item.Price5,
//        Pack6: item.Pack6,
//        Price6: item.Price6,
//        Formula: item.Formula,
//        Structure: item.Structure,
//        MolecularWeight: item.MolecularWeight,
//        MeltingPoint: item.MeltingPoint,
//        BoilingPoint: item.BoilingPoint,
//        Synonym: item.Synonym,
//        Apiname: item.Apiname,
//        Storage: item.Storage,
//        Density: item.Density,
//        PhysicalState: item.PhysicalState,
//        MoistureContent: item.MoistureContent,
//      })

//      this.cartService.addtoCart(this.selectedItemforCart);
//      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
//      this.toast.add({ severity: 'success', summary: 'Success', life: 1000, detail: 'Added to Cart Successfully' });
//    }
//    this.toast.add({ severity: 'warn', summary: 'Warning', life: 1000, detail: 'Product already exist in cart' });

//  }



//  filterTests() {
//    debugger
//    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
//    let filtered: any[] = [];
//    var UploadFile = new FormData()
//    UploadFile.append("Param", this.selectedcompanys)

//    var url = "api/SCIKEM/Search_Products"
//    this.generalService.PostData(url, UploadFile).then(data => {
//      this.searchcompany = data
//    }, err => {
//      //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
//    });
//  }
//  clearItem(autocomplete: any) {
//    this.searchlist = [];
//    debugger
//    autocomplete.value = '';
//    this.filterTests();
//    window.location.reload();
//    //this.searchlist = this.searchcompany;
//  }
//  filter(event:any) {
//    debugger
//    let query = event.detail.value;

//    this.searchlist = [];

//    this.searchlist = this.searchcompany.filter((KR:any) => {
//      return (
//        KR.ChemicalName.toLowerCase().indexOf(query.toLowerCase()) > -1);

//    })

//  }
//  search(ev:any) {
//    debugger
//    if (ev) {
//      this.searchcompany = this.searchlist.filter((a: any) => a.ChemicalName == ev.ChemicalName);
//    }
//    else {
//      this.searchcompany = this.searchlist

//    }
//  }

//  keyUp(ev:any) {
//    debugger
//    this.searchcompany = this.searchlist.filter((item:any) => {
//      return item.ChemicalName.toLowerCase().startsWith(ev.target.value.toLowerCase())


//    });
//  }



//}

