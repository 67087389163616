<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Our Certificates</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:white;">Our Certificates</li>
            </ul>
        </div>
    </nav>
    <div class="container">
       <br />
            <div class="img-box">
                <a href="../../../assets/Images/ISO_45001 QVA.pdf" target="_blank">
                    <img src="../../../assets/Images/registrationcertificate.JPG" alt=""/>
                    <p>Registration Certificate</p>
                </a>
            </div>
            <div class="img-box">
                <a href="../../../assets/Images/ISO_50001 QVA.pdf" target="_blank">
                    <img src="../../../assets/Images/compliancecertificate.JPG" alt="" />
                    <p>Compliance Certificate</p>
                </a>
            </div>
          
    </div>
</div>
