<div>
    <h3>
        Add address
    </h3>
    <div class="row">
        <div class="col-lg-3 card" style="margin-top:10%;margin-left:5%">
            <i class="fa fa-plus addIcon" (click)="plus()" pTooltip="ADD" tooltipPosition="top" style="font-size:100px;"></i>

        </div>
        <div *ngFor="let record of category" class="col-lg-6 card" style="margin-top:10%;margin-left:5%">
            <p>{{record.ddd}}</p>
            <p>{{record.Address}}</p>
            <p>{{record.Landmark}}</p>
            <p>{{record.City}}</p>
            <p>{{record.State}}</p>
            <p>{{record.Pincode}}</p>
        </div>


    </div>
    
</div>
