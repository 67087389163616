<section role=slider>
    <div class="container">
        <h1>Welcome To Azolechem</h1>
        <p>
            We form alliances,<b>
                Azole Chem provides complete catalog goods and services in the pharmaceutical, agrochemical,
                and other related industries.
            </b>The company was founded on a solid foundation and has since grown on trust.<br />
            Azole Chem offers premium collaboration for the goods and services that improve our clients' and business
            partners' capacity and productivity in areas that are essential to their success.
        </p>
    </div>
    <div class="slider-contain">
        <div class="small-features">
            <div class="small">
                <span>We love what we do.</span>
            </div>

            <!--<img src="../../../assets/Images/home1.webp" />-->
            <div class="small" style="background-image: url(../../../assets/Images/home1.webp)"></div>
        </div>
        <div class="main-feature" style=" background-image:url(../../../assets/Images/home.webp)">
        </div>
    </div>
    <div class="container">
        <p>
            We have the ability to steer our business's operations through ingenuity, inventiveness, and progressivism in
            terms of procedures, tools, and operational procedures.<b>
                In order to achieve "Superior Customer Satisfaction,"
                we feel that quality deliverance is essential in every part of our business process, even as we strive for
                perfection in our services.
            </b> <br />In our operations, we also adhere to a number of quality- and knowledge-based protocols and methods.
            To purchase specialized construction bricks and screening decks via a one-time payment method. Bench chemists are able to get quotations for our products and browse our library of various chemicals.
        </p>
    </div>
</section>
