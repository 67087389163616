import { Component } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { CartService } from '../../Services/cart.service'
declare var $
@Component({
  selector: 'app-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent {

    deliveryCharge: any;
    offerAmount: any;
    grandTotal: any;
    billNo: any;
    orderDate: any;
    orderDetails: any = [];
    orderNo: string;
    LoginDetails: any;
    loginDet: any;
    loginDetails: any;
    HomeUrl: any;
    Sname:any
    TotalPrice: any
    cartItems: any;
    invoicedata: any;
    subTotal: number;
    Grandtotal=0;
    constructor(public generalService: GeneralService, public activeroute: ActivatedRoute, public CartService: CartService,
        public http: HttpClient, public router: Router) {
      
        this.Sname = this.activeroute.snapshot.paramMap.get('Invoice').replace(/\_/g, " ").trim();
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.orderNo = this.activeroute.snapshot.paramMap.get('No')
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        this.generalService.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.getOrdersbasedOnOrderDetails();
               
            }
            else {

                this.Sname = res[0].OrderID;
                this.getOrdersbasedOnOrderDetails();
             
            }
        })

        this.CartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

        })
        this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
            this.subTotal += (a.Price * 1) * (a.Quantity)
            // Assuming Price is a number
            const gstTax = this.subTotal * (a.GSTTAX / 100);
            this.Grandtotal = this.subTotal + gstTax;
        })
        
    }

    ngOnInit(): void {
       
    }

    getOrdersbasedOnOrderDetails() {
     

        let formData: FormData = new FormData();
        formData.append('Param', this.Sname);
        var url = "api/Azole/Get_Invoice_basedon_OrderId";
        this.generalService.PostData(url, formData).then((data: any) => {
            this.invoicedata=data
            if (data && data.length > 0) {
                // Assuming data is an array of orders

                // Assigning the first order's details
                this.orderDate = data[0].CreatedDate;
                this.orderNo = data[0].OrderID;
               

                // Calculate totalAmount (sum of all TotalPrice)
                let totalAmount = 0;
                for (let i = 0; i < data.length; i++) {
                    totalAmount += data[i].TotalPrice;

                   
                }
                this.invoicedata.forEach((a: any) => {
                  /*this is looping for getiing total amount*/
                    this.subTotal = (a.Price * 1) * (a.Quantity)
                    // Assuming Price is a number
                    const gstTax = this.subTotal * (a.GSTTAX / 100);
                    this.Grandtotal += this.subTotal + gstTax;
                })

                // Assigning totalAmount
                this.TotalPrice = totalAmount;

                // Assuming the offerAmount and deliveryCharge are also part of the first order's details
                this.offerAmount = data[0].OfferPrice;
                this.deliveryCharge = 0; // You might need to adjust this based on your logic

                // Assigning all order details
                this.orderDetails = data;
            } 
        });
    }





     





    print():
        void {
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        //popupWin = window.open('','_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          
* {
  font-size: 14px;
  font-family: Arial !important;
width:100%;
}



td,
th,
tr,
table {
  border-top: 1px solid black;
  font-size: 12px;
width: 100% !important;

}
.ticket {
  width: 100% !important;
  /*max-width: 155px;*/
  max-width: 100% !important;
font-family: Arial !important;
  font-size: 14px;
}

img {
  max-width: inherit;
  width: inherit;
}

@media print {
  body {
font-family: Arial !important;
        height:100px; 
        width: 100%;


        /* to centre page on screen*/
       
    }
  .hidden-print,
  .hidden-print * {
    display: none !important;
width:100%;
  }
}
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
        );
        popupWin.document.close();
    }

}

