
<div id="about" class="about-area ptb-100"style="margin-top:-4rem">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="mt-5 pt-5">
                    <button type="submit" class="btn btn-danger hidden-print" routerLink="/MyOrders">Back</button>
                </div>
            </div>
            <div class="col-lg-8"> </div>

        </div><br />
        <div class="row">
            <div id="print-section">
                <table style="background-color: #fff;">
                    <tr>

                        <td style="text-align:center;background-color:#ffffff;">
                            <img src="../../../../assets/Images/Logo.PNG" style="width:10% !important;" alt="Logo">
                        </td>
                    </tr>

                    <tr>
                        <td style="text-align: center; background-color:#ffffff;color:black;">
                            <div class="" > Tax Invoice</div>
                            <!--<img src="../../../../../assets/img/app/VTlogo.png" style="width:20% !important;" alt="Logo">-->
                        </td>
                    </tr>
                    <tr style="background-color:white;color:black">
                        <td class="Item" style="background-color:white;color:black">
                            <div class="row">
                                <div class="col-lg-12" style="text-align:left">
                                    <div>
                                        <b class="strg1">Bill To:</b><br />
                                        <span class="strg" style="color:black"> {{orderDetails[0].UserName}} - {{orderDetails[0].Phone1}} </span>
                                        <br />
                                        {{orderDetails[0].BillingHNO}}, {{orderDetails[0].BillingLanmark}}, {{orderDetails[0].BillingAddress}},
                                        <br />

                                        {{orderDetails[0].BillingCity}}, {{orderDetails[0].BillingState}} - {{orderDetails[0].BillingPincode}}.<br />
                                    </div>
                                    <div><br/>
                                        <b class="strg1">Delivery To:</b><br />
                                        <span class="strg" style="color:black"> {{orderDetails[0].Customername}} - {{orderDetails[0].Mobileno}} </span>
                                        <br />
                                        {{orderDetails[0].DeliveryHNO}}, {{orderDetails[0].DeliveryLanmark}}, {{orderDetails[0].DeliveryAddress}},
                                        <br />

                                        {{orderDetails[0].DeliveryCity}}, {{orderDetails[0].DeliveryState}} - {{orderDetails[0].DeliveryPincode}}.<br />
                                    </div>
                                </div>

                            </div>


                            <!--{{GSTNUMBER}}-->
                            <!--<div class="strg" style=" text-align: Right !important">YK mart</div>
                                <div style=" text-align: Right !important">
                                    Herbal House, road no 3,servey no 255, Kuntloor
                                </div>
                                <div style=" text-align: Right !important">
                                    Telangana
                                </div>-->
                            <!--<div class="strg" style=" text-align: Right !important">
                                  GSTIN : 36AHWPM4614R2ZC
                            </div>-->
                        </td>


                    </tr>
                    <tr >
                        <td style="background-color:white;color:black">
                            <div style="border:none !important">
                                <table style="width:40%;margin-left: 60%;">
                                    <tr style="border:none !important;">
                                        <td class="td_class1" style="background-color:white;color:black"> <span class="strg"style="color:black;background-color:#ffffff;color:black;">Order Id :</span></td>
                                        <td style="background-color:white;color:black"><span class="ordstrn"> {{orderNo}}</span></td>
                                    </tr>

                                    <tr style="background-color:#ffffff;">
                                        <td style="background-color:white;color:black"><span class="strg" >Order Date :</span></td>
                                        <td style="background-color:white;color:black"><span class="strg"><span class="ordstrn"> {{orderDate | date:'dd-MMM-yyyy'}}</span></span></td>
                                    </tr>
                                </table>
                                <table style="width:40%; margin-top: -90px;">
                                    <tr>
                                        <td style="background-color:white;color:black"><span class="strg">Invoice No :</span></td>
                                        <td style="background-color:white;color:black"><span class="strg"><span class="ordstrn">IN{{orderNo}}</span></span></td>
                                    </tr>
                                    <tr >
                                        <td style="background-color:white;color:black"><span class="strg">Invoice Date :</span></td>
                                        <td style="background-color:white;color:black"><span class="strg"><span class="ordstrn"> {{orderDate | date:'dd-MMM-yyyy'}}</span></span></td>
                                    </tr>
                                    <!--<tr>
                                      <td><span class="strg">Invoice Time:</span></td>
                                      <td><span class="strg"><span class="ordstrn"> {{orderDate | date:'hh:mm a'}}</span></span></td>
                                    </tr>-->
                                </table>


                            </div>

                        </td>
                    </tr>
                    <tr>
                        <td class="Item" style="padding:0;">
                            <table style="width:100%;">

                                <tr style="font-size: 15px;color: #000;font-weight: bold;letter-spacing: 0.5px;">
                                    <td style="width:2%;">Sl.No</td>
                                    <td style="width:10%;text-align:left">Catelog No</td>
                                    <td style="width:6%;text-align:left">Chemical Name</td>
                                    <td style="width:8%; text-align:center">Pack Size</td>
                                    <td style="width:5%;text-align:left">HSN Code</td>
                                    <td style="width:10%; text-align:center">CAS No</td>
                                    <td style="width:10%;text-align:left">Qty (Number)</td>
                                    <td style="width:8%; text-align:left">Price (₹)</td>
                                    <td style="width:7%; text-align:left">GST %</td>
                                    <td style="width:10%; text-align:left">GST Price (₹)</td>




                                </tr>

                                <tr *ngFor="let item1 of orderDetails;let i=index" style="font-family: Arial;
                                    font-size: 15px;
                                    color: #000;
                                    font-weight: normal;
                                    letter-spacing: 0.5px;">
                                    <td style="width:5%;">{{i+1}}</td>
                                    <td style="width:5%;">{{item1.CatalogNo }}</td>
                                    <td style="width:14%;">{{item1.ChemicalName}}</td>
                                    <td style="width:6%;">{{item1.PackSize}}</td>
                                    <td style="width:10%;text-align:right">{{item1.HSNCode }}</td>
                                    <td style="width:5%; text-align:right">{{item1.CAS}}</td>

                                    <td style="width:5%;">{{item1.Quantity }}</td>
                                    <td style="width:5%;">{{item1.Price }}</td>
                                    <td style="width:5%;">{{item1.GSTTAX }}</td>
                                    <td style="width:5%;">{{((item1.Price*item1.Quantity)* (item1.GSTTAX)/100)}}</td>





                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr></tr>
                    <tr>
                        <td style="padding:0;">
                            <table style="width:100%;">
                                <tr style="font-family: 'Poppins'; font-size: 15px; color: #000; font-weight: bold;letter-spacing: 0.5px;">
                                    <td style="background-color:#ffffff;color:black;">
                                        <div class="strg1" style=" text-align: Right !important;"> Total Amount (₹) :  {{Grandtotal}}</div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <tr >
                        <td style="background-color:#ffffff;color:black;">
                            <div style="text-align:left; background-color:white;color:black;">
                                We declare that this invoice shows the actual price of the goods described and that all<br />
                                particulars are true and correct.
                            </div>
                            <div style="text-align:right;background-color:white;color:black;">
                                Authorized Signatory
                            </div>
                        </td>
                    </tr>
                    <tr><td style="text-align:center;background-color:white;color:black;"> This is computer generated invoice</td></tr>
                    <tfoot>



                    </tfoot>
                </table>
                <p style="text-align:right">
                    <br />
                    <button type="submit" class="btn btn-primary hidden-print" (click)="print()">Print</button>
                    &nbsp;
                    <button type="submit" class="btn btn-danger hidden-print" routerLink="/MyOrders">Cancel</button>
                </p>

                <script src="script.js"></script>
            </div>

        </div>
    </div>
    <!--this code use for google analyticals-->
    </div>








    <style>

        .sticky-container1 {
            padding: 0;
            margin: 500px 0 0;
            position: fixed;
            right: -169px;
            top: 230px;
            width: 210px;
            z-index: 1100;
        }

        .sticky1 {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 999;
            margin-top: 20rem;
        }

            .sticky1 li {
                border: 1px solid rgba(18, 54, 84, 0.43);
                list-style-type: none;
                background-color: #fff;
                color: #efefef;
                height: 43px;
                padding: 0;
                margin: 0 0 8px;
                transition: all .25s ease-in-out;
                cursor: pointer;
            }

                .sticky1 li img {
                    float: left;
                    margin: 5px 5px 5px 4px;
                }

                .sticky1 li p {
                    padding-top: 5px;
                    margin: 0;
                    line-height: 16px;
                    font-size: 11px;
                }

                    .sticky1 li p a {
                        text-decoration: none;
                        color: #2c3539;
                    }

                .sticky1 li p {
                    padding-top: 5px;
                    margin: 0;
                    line-height: 16px;
                    font-size: 11px;
                }

                .sticky1 li:hover {
                    margin-left: -115px;
                }
    </style>

    <style>
        .modal-dialog {
            max-width: 60% !important;
            margin: 1.75rem auto !important;
        }

        .card {
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.11), 0 3px 6px rgba(0, 0, 0, 0.02);
        }

        .custom.tabs-primary {
            background-color: rgba(62, 80, 180, 0) !important;
            border: 1px solid #ffffff !important;
            COLOR: #000 !important;
        }

        .nav-item .nav-link {
            color: rgba(0, 0, 0, 0.8) !important;
        }

            .nav-item .nav-link.active {
                background-color: #ffffff !important;
                border-bottom: 3px solid #9ed9f7 !important;
            }

            .nav-item .nav-link:hover {
                background-color: #9ed9f7 !important;
                border-bottom: 3px solid #9ed9f7 !important;
            }

        .HideDescription {
            width: 100%;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .strg_c {
            font-family: poppins;
            font-size: 12px;
            color: #000;
            letter-spacing: 0.5px;
            line-height: 1.8;
            text-align: center;
            padding-top: 0;
        }

        .strg {
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            color: #000;
            letter-spacing: 0.5px;
            text-align: center;
            padding-top: 0.5rem
        }

        . strg1 {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #000;
            letter-spacing: 0.5px;
            text-align: center;
            padding-top: 0.5rem
        }

        .ordstrn {
            font-family: Arial;
            font-size: 15px;
            color: #000;
            font-weight: normal;
            letter-spacing: 0.5px;
        }

        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 100%;
        }

        td, th {
            border: 1px solid #dddddd;
            text-align: right;
            padding: 10px;
        }

        #plugin {
            height: 30% !important;
            position: absolute;
            width: 100%;
        }
    </style>

