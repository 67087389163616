
<div id="about" class="about-area pt-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>My Favorites</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:white;">My Favorites</li>
            </ul>
        </div>
    </nav>



</div>

<div class="container web">
    <div class="row">
        <div class="col-lg-10"></div>
    </div>
    <div class="row">
        <div class="col-lg-12 contain">
            <div class="card">
                <div class="row header">
                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-2"><div>Product Image</div></div>
                            <div class="col-lg-2" style="margin-left: -40px;"><div>Product Name</div></div>
                            <div class="col-lg-2"><div>HSN No</div></div>
                            <div class="col-lg-2"><div>Pack Name</div></div>
                            <div class="col-lg-2"><div>Price (₹)</div></div>
                            <div class="col-lg-2"><div>Quantity</div></div>
                        </div>
                    </div>
                    <div class="col-lg-1"></div>
                </div>
                <div class="row tablerow" *ngFor="let item of favlist">
                    <div class="col-lg-11">
                        <div class="row">
                            <div class="col-lg-2">
                                <figure *ngIf="item.Record_Image">
                                    <img [src]="HomeUrl + item.Record_Image" class="card_image image" alt="DB">
                                </figure>
                                <div *ngIf="!item.Record_Image">No Image</div>
                            </div>
                            <div class="col-lg-2" style="margin-left: -40px;">
                                <div class="nomargin" style="color: #295fa9;">
                                    <span class="product-name">{{item.ProductName.replace(' ', '\u00A0')}}</span><br>
                                    <span>{{item.CAS_No}}</span><br>
                                    <span>{{item.Catalogue_Number}}</span>
                                </div>
                            </div>
                            <div class="col-lg-2">{{item.HSN}}</div>
                            <div class="col-lg-2 ">{{item.PackName}}</div>
                            <div class="col-lg-2 ">{{item.PackPrice}}.00</div>
                            <div class="col-lg-2 ">{{item.Quantity}}</div>
                        </div>
                    </div>
                    <div class="col-lg-1">
                        <div class="row">
                            <div class="col-lg-6">
                                <div>
                                    <i class="fa fa-cart-plus cart-icon" style="cursor:pointer" (click)="Addtocart(productDetails[0]?.Gst, item.Catalogue_Number, item)"></i>
                                </div>
                            </div>
                            <div class="col-lg-6" (click)="deleteFavorite(item.FavId)">
                                <i class="fa fa-trash" style="color: orangered; cursor:pointer"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

    <div class="container cardnone mb-2">
        <div class="card mt-1 p-3" *ngFor="let item of favlist" style="box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;">


            <div class="row   " >
                <div class="col-6">
                    <div class="col-12"><b style="color:#295fa9;">{{item.CAS_No}}</b></div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-2" *ngIf="item.Record_Image">
                                <figure>  <img src="{{HomeUrl+item.Record_Image}}" class="card_image image" alt="DB"></figure>
                            </div>
                            <div class="col-lg-2" *ngIf="!item.Record_Image">
                                No Image
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div>
                                            <i class="fa fa-cart-plus cart-icon" style="cursor:pointer" (click)="Addtocart(productDetails[0]?.Gst,item.Catalogue_Number,item)"></i>
                                        </div>
                                    </div>
                                    <div class="col-6" (click)="deleteFavorite(item.FavId)">
                                        <i class="fa fa-trash" style="color: orangered;cursor:pointer"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <div class="col-lg-2"><b>{{item.Catalogue_Number}}</b></div>
                    <div class="col-lg-2">{{item.HSN}}</div>
                    <div class="col-lg-2 ">{{item.PackPrice}}</div>
                    <div class="col-lg-2 ">{{item.PackName}}</div>
                    <div class="col-lg-1 ">Quantity&nbsp;{{item.Quantity}}</div>
                </div>



            </div>

        </div>
    </div>















