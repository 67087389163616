import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../../Services/generalservice.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    HomeURL: any
    arr: any;
    showCategories: boolean = false;
    Categories: any;
    loginDet: any;
    loginDetails: any;
    cartItems: any;
    //@Output() someEvent = new EventEmitter<string>();
    cartItemsCount: any; category: any;
    token: any;
    constructor(private viewportScroller: ViewportScroller, private route: ActivatedRoute, public router: Router,
        public generalService: GeneralService, public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
        });
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
     //   this.cartservice.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
           // this.cartItems = res

       // })
    }

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

    ngOnInit() {
        this.GetCategories();
  }
    GetCategories() {
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {
            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({});
            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');
            var url = this.HomeURL + "api/Azole/Category_Crud";
            var accessToken = this.token;

            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`
            });

            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {
                     
                    this.Categories = data;
                },
                (err) => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

    navigatingToSearchedProduct(Productname: any) {
        
        this.router.navigate(['/Product/' + Productname]);
    }

    navigatingToSearchedProducts(CategoryID: number, CategoryName: any) {
       
        this.router.navigate(['/Products'], { queryParams: { categoryID: CategoryID, CategoryName: CategoryName } });
        localStorage.setItem("cat", CategoryName);
    }


}
