import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpHeaders } from '@angular/common/http';
@Component({
    selector: 'app-ledgerreports',
    templateUrl: './ledgerreports.component.html',
    styleUrls: ['./ledgerreports.component.scss']
})
export class LedgerreportsComponent {
    Param2: string;
    SplBio: string;
    SearchKeyWord: any;;
    cols: any;
    AllProducts: any;
    Reportdata: any;
    spinner: boolean = false;
    loginDet: any;
    loginDetails: any;
    regid: any;
    HomeUrl: any;
    token: any;
    constructor(public generalService: GeneralService, public router: Router, public http: HttpClient) {
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)


        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }




    ngOnInit() {


        this.cols = [
            { field: 'UserName', header: ' Name', width: '250px' },
            { field: 'orderid', header: 'OrderId', width: '200px' },
            { field: 'amount', header: 'amount', width: '183px' },
            { field: 'Transacationstatus', header: 'status', width: '120px' },
            { field: 'Balance', header: 'Bal', width: '185px' },
            { field: 'Description', header: 'Description', width: '200px' },
            { field: 'TagName', header: 'key Name', width: '250px' },
            { field: 'createddate', header: 'Date&time', width: '150px' }
        ]
        this.GetReports(this.regid);
    }


    GetReports(regid: any) {
        
        this.regid = this.loginDetails[0].RegId
        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }


            var UploadFile = new FormData();
            UploadFile.append("Param1", this.regid);
            UploadFile.append("Flag", '2');

            var url = this.HomeUrl + "api/Azole/GetAmountTranscations";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {
              

                this.Reportdata = data;
            },
                err => {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

}
